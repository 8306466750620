import { Divider, Typography } from "antd";
import ContactCard from "./Card";

const Contact = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full my-10">
      <div className="flex flex-col items-center w-4/5">
        <Divider orientation="left">
          <Typography.Title level={3}>Contact Us</Typography.Title>
        </Divider>

        <ContactCard />
      </div>
    </div>
  );
};

export default Contact;
