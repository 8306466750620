import React, { Fragment, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom"; // Import NavLink
import { useSelector } from "react-redux";
import { MenuOutlined } from "@ant-design/icons";
import { Menu, Drawer, Button, Typography } from "antd";
import { ROUTES_MAP } from "../../constants/common";
import { selectLoggedInArtist } from "../../reducers/artist";
import { selectLoggedInClient } from "../../reducers/client";
import theartistnetwork from "../../images/theartistnetwork.png";
import LoginModal from "./Modal/LoginModal";
import LogoutModal from "./Modal/LogoutModal";

const INITIAL_ROUTES_ARRAY = [
  ROUTES_MAP.HOME,
  ROUTES_MAP.EVENTS,
  ROUTES_MAP.ARTISTS,
  ROUTES_MAP.CONTACT,
];

const ARTIST_ROUTES_ARRAY = [
  ROUTES_MAP.HOME,
  ROUTES_MAP.ARTISTS,
  ROUTES_MAP.ARTIST_MY_PROFILE,
  ROUTES_MAP.ARTIST_EDIT_PROFILE,
  ROUTES_MAP.ARTIST_BOOKINGS,
  ROUTES_MAP.ARTIST_ADDRESSES,
];

const CLIENT_ROUTES_ARRAY = [
  ROUTES_MAP.HOME,
  ROUTES_MAP.EVENTS,
  ROUTES_MAP.ARTISTS,
  ROUTES_MAP.CLIENT_EDIT_PROFILE,
  ROUTES_MAP.CLIENT_BOOKINGS,
  ROUTES_MAP.CLIENT_ADDRESSES,
];

const Navbar = () => {
  const navigate = useNavigate();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const loggedInArtist = useSelector(selectLoggedInArtist);
  const loggedInClient = useSelector(selectLoggedInClient);

  let navbarArray = INITIAL_ROUTES_ARRAY;

  if (loggedInArtist) {
    navbarArray = ARTIST_ROUTES_ARRAY;
  } else if (loggedInClient) {
    navbarArray = CLIENT_ROUTES_ARRAY;
  }

  const isPersonLoggedIn = loggedInArtist || loggedInClient;

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
    closeDrawer();
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
    closeDrawer();
  };

  const openSignupModal = () => {
    setIsSignupModalOpen(true);
    closeDrawer();
  };

  const closeSignupModal = () => {
    setIsSignupModalOpen(false);
    closeDrawer();
  };

  const openLogoutModal = () => {
    setIsLogoutModalOpen(true);
    closeDrawer();
  };

  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
    closeDrawer();
  };

  return (
    <nav>
      <LoginModal
        isModalOpen={isLoginModalOpen}
        closeModal={closeLoginModal}
        isLogin
      />
      <LoginModal
        isModalOpen={isSignupModalOpen}
        closeModal={closeSignupModal}
      />
      <LogoutModal
        isModalOpen={isLogoutModalOpen}
        closeModal={closeLogoutModal}
      />
      <div className="container flex items-center justify-between px-4 py-4 mx-auto">
        <div className="w-[100px]">
          <img
            className="hover:cursor-pointer"
            onClick={() => navigate(ROUTES_MAP.HOME.path)}
            src={theartistnetwork}
            alt="The Artist Network"
          />
        </div>

        <div className="hidden space-x-8 lg:flex">
          {navbarArray.map((route) => (
            <NavLink
              key={route.key}
              to={route.path}
              className={({ isActive }) =>
                isActive ? "text-gray-900 font-bold" : "text-gray-500"
              }
            >
              <Typography className="m-0 hover:text-gray-400">
                {route.name}
              </Typography>
            </NavLink>
          ))}
        </div>

        {/* Buttons (Desktop) */}
        <div className="hidden space-x-4 lg:flex">
          {!isPersonLoggedIn ? (
            <Fragment>
              <Button type="primary" onClick={openLoginModal}>
                Login
              </Button>
              <Button type="default" onClick={openSignupModal}>
                Signup
              </Button>
            </Fragment>
          ) : (
            <Button danger onClick={openLogoutModal}>
              Logout
            </Button>
          )}
        </div>

        {/* Mobile Menu Icon */}
        <div className="lg:hidden">
          <MenuOutlined onClick={showDrawer} className="text-xl" />
        </div>
      </div>

      {/* Drawer (Mobile) */}
      <Drawer
        width={300}
        placement="right"
        onClose={closeDrawer}
        open={isDrawerVisible}
        className="lg:hidden"
        closable={false}
        title={null}
        maskClosable={true}
      >
        <Menu mode="vertical" className="border-none" onClick={closeDrawer}>
          {navbarArray.map((route) => (
            <Menu.Item key={route.key}>
              <NavLink
                to={route.path}
                className={({ isActive }) =>
                  isActive ? "font-bold text-gray-900" : "text-gray-500"
                }
              >
                {route.name}
              </NavLink>
            </Menu.Item>
          ))}

          {!isPersonLoggedIn ? (
            <Fragment>
              <Menu.Item key="login" className="mt-6 hover:bg-transparent">
                <Button
                  type="primary"
                  className="w-full"
                  onClick={openLoginModal}
                >
                  Login
                </Button>
              </Menu.Item>
              <Menu.Item key="signup" className="hover:bg-transparent">
                <Button
                  type="default"
                  className="w-full"
                  onClick={openSignupModal}
                >
                  Signup
                </Button>
              </Menu.Item>
            </Fragment>
          ) : (
            <Menu.Item key="logout" className="mt-6 hover:bg-transparent">
              <Button className="w-full" danger onClick={openLogoutModal}>
                Logout
              </Button>
            </Menu.Item>
          )}
        </Menu>
      </Drawer>
    </nav>
  );
};

export default Navbar;
