import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons"; // Import TwoTone icons
import {
  selectCreateArtistError,
  clearCreateArtistErrorMessage,
  selectAddArtistMediaSuccessMessage,
  selectAddArtistMediaErrorMessage,
  clearAddArtistMediaSuccessMessage,
  clearAddArtistMediaErrorMessage,
  selectDeleteArtistMediaErrorMessage,
  selectDeleteArtistMediaSuccessMessage,
  clearDeleteArtistMediaErrorMessage,
  clearDeleteArtistMediaSuccessMessage,
  selectAddTermsAndConditionsErrorMessage,
  selectDeleteTermsAndConditionsErrorMessage,
  selectAddTermsAndConditionsSuccessMessage,
  selectDeleteTermsAndConditionsSuccessMessage,
  clearAddTermsAndConditionsErrorMessage,
  clearDeleteTermsAndConditionsErrorMessage,
  clearAddTermsAndConditionsSuccessMessage,
  clearDeleteTermsAndConditionsSuccessMessage,
  selectUpdateAboutInfoErrorMessage,
  selectUpdateGeneralInfoErrorMessage,
  selectUpdateAboutInfoSuccessMessage,
  selectUpdateGeneralInfoSuccessMessage,
  clearUpdateAboutInfoErrorMessage,
  clearUpdateGeneralInfoErrorMessage,
  clearUpdateAboutInfoSuccessMessage,
  clearUpdateGeneralInfoSuccessMessage,
  selectUpdateWorkInfoErrorMessage,
  selectUpdateWorkInfoSuccessMessage,
  clearUpdateWorkInfoErrorMessage,
  clearUpdateWorkInfoSuccessMessage,
  selectArtistLoginError,
  clearArtistLoginErrorMessage,
  selectFetchAvailabilitiesErrorMessage,
  clearFetchAvailabilitiesErrorMessage,
  selectCancelBookingByArtistErrorMessage,
  selectConfirmBookingByArtistErrorMessage,
  selectCancelBookingByArtistSuccessMessage,
  selectConfirmBookingByArtistSuccessMessage,
  clearCancelBookingByArtistErrorMessage,
  clearConfirmBookingByArtistErrorMessage,
  clearCancelBookingByArtistSuccessMessage,
  clearConfirmBookingByArtistSuccessMessage,
} from "../../reducers/artist";
import {
  clearClientErrorMessage,
  clearClientUpdateGeneralInfoErrorMessage,
  clearClientUpdateGeneralInfoSuccessMessage,
  clearCreateRestaurantError,
  clearRestaurantLoginErrorMessage,
  selectClientErrorMessage,
  selectClientUpdateGeneralInfoErrorMessage,
  selectClientUpdateGeneralInfoSuccessMessage,
  selectCreateRestaurantError,
  selectRestaurantLoginErrorMessage,
  selectMakeBookingErrorMessage,
  clearMakeBookingErrorMessage,
  selectCancelBookingByClientSuccessMessage,
  selectCancelBookingByClientErrorMessage,
  clearCancelBookingByClientErrorMessage,
  clearCancelBookingByClientSuccessMessage,
} from "../../reducers/client";
import {
  clearCreateAddressError,
  clearCreateAddressSuccess,
  clearDeleteAddressError,
  clearDeleteAddressSuccess,
  clearUpdateAddressError,
  clearUpdateAddressSuccess,
  selectCreateAddressError,
  selectCreateAddressSuccess,
  selectDeleteAddressError,
  selectDeleteAddressSuccess,
  selectUpdateAddressError,
  selectUpdateAddressSuccess,
} from "../../reducers/address";

const Message = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  // Error Messages
  const artistLoginErrorMessage = useSelector(selectArtistLoginError);
  const createArtistErrorMessage = useSelector(selectCreateArtistError);
  const createRestaurantErrorMessage = useSelector(selectCreateRestaurantError);
  const restaurantLoginErrorMessage = useSelector(
    selectRestaurantLoginErrorMessage
  );
  const createAddressErrorMessage = useSelector(selectCreateAddressError);
  const updateAddressErrorMessage = useSelector(selectUpdateAddressError);
  const deleteAddressErrorMessage = useSelector(selectDeleteAddressError);
  const addArtistMediaErrorMessage = useSelector(
    selectAddArtistMediaErrorMessage
  );
  const deleteArtistMediaErrorMessage = useSelector(
    selectDeleteArtistMediaErrorMessage
  );
  const addTermsAndConditionsErrorMessage = useSelector(
    selectAddTermsAndConditionsErrorMessage
  );
  const deleteTermsAndConditionsErrorMessage = useSelector(
    selectDeleteTermsAndConditionsErrorMessage
  );
  const updateAboutInfoErrorMessage = useSelector(
    selectUpdateAboutInfoErrorMessage
  );
  const updateGeneralInfoErrorMessage = useSelector(
    selectUpdateGeneralInfoErrorMessage
  );
  const updateWorkInfoErrorMessage = useSelector(
    selectUpdateWorkInfoErrorMessage
  );
  const clientErrorMessage = useSelector(selectClientErrorMessage);
  const clientUpdateGeneralInfoErrorMessage = useSelector(
    selectClientUpdateGeneralInfoErrorMessage
  );
  const fetchArtistAvailabilitiesErrorMessage = useSelector(
    selectFetchAvailabilitiesErrorMessage
  );
  const makeBookingErrorMessage = useSelector(selectMakeBookingErrorMessage);
  const cancelBookingByClientErrorMessage = useSelector(
    selectCancelBookingByClientErrorMessage
  );
  const cancelBookingByArtistErrorMessage = useSelector(
    selectCancelBookingByArtistErrorMessage
  );
  const confirmBookingByArtistErrorMessage = useSelector(
    selectConfirmBookingByArtistErrorMessage
  );

  // Success Messages
  // const artistLoginSuccessMessage = useSelector(selectArtistSu);
  const createAddressSuccessMessage = useSelector(selectCreateAddressSuccess);
  const updateAddressSuccessMessage = useSelector(selectUpdateAddressSuccess);
  const deleteAddressSuccessMessage = useSelector(selectDeleteAddressSuccess);
  const addArtistMediaSuccessMessage = useSelector(
    selectAddArtistMediaSuccessMessage
  );
  const deleteArtistMediaSuccessMessage = useSelector(
    selectDeleteArtistMediaSuccessMessage
  );
  const addTermsAndConditionsSuccessMessage = useSelector(
    selectAddTermsAndConditionsSuccessMessage
  );
  const deleteTermsAndConditionsSuccessMessage = useSelector(
    selectDeleteTermsAndConditionsSuccessMessage
  );
  const updateAboutInfoSuccessMessage = useSelector(
    selectUpdateAboutInfoSuccessMessage
  );
  const updateGeneralInfoSuccessMessage = useSelector(
    selectUpdateGeneralInfoSuccessMessage
  );
  const updateWorkInfoSuccessMessage = useSelector(
    selectUpdateWorkInfoSuccessMessage
  );
  const clientUpdateGeneralInfoSuccessMessage = useSelector(
    selectClientUpdateGeneralInfoSuccessMessage
  );
  const cancelBookingByClientSuccessMessage = useSelector(
    selectCancelBookingByClientSuccessMessage
  );
  const cancelBookingByArtistSuccessMessage = useSelector(
    selectCancelBookingByArtistSuccessMessage
  );
  const confirmBookingByArtistSuccessMessage = useSelector(
    selectConfirmBookingByArtistSuccessMessage
  );

  useEffect(() => {
    if (artistLoginErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: artistLoginErrorMessage,
        onClose: () => dispatch(clearArtistLoginErrorMessage()),
      });
    }

    if (createArtistErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: createArtistErrorMessage,
        onClose: () => dispatch(clearCreateArtistErrorMessage()),
      });
    }

    if (createRestaurantErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: createRestaurantErrorMessage,
        onClose: () => dispatch(clearCreateRestaurantError()),
      });
    }

    if (createAddressErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: createAddressErrorMessage,
        onClose: () => dispatch(clearCreateAddressError()),
      });
    }

    if (updateAddressErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: updateAddressErrorMessage,
        onClose: () => dispatch(clearUpdateAddressError()),
      });
    }

    if (deleteAddressErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: deleteAddressErrorMessage,
        onClose: () => dispatch(clearDeleteAddressError()),
      });
    }

    if (addArtistMediaErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: addArtistMediaErrorMessage,
        onClose: () => dispatch(clearAddArtistMediaErrorMessage()),
      });
    }

    if (deleteArtistMediaErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: deleteArtistMediaErrorMessage,
        onClose: () => dispatch(clearDeleteArtistMediaErrorMessage()),
      });
    }

    if (addTermsAndConditionsErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: addTermsAndConditionsErrorMessage,
        onClose: () => dispatch(clearAddTermsAndConditionsErrorMessage()),
      });
    }

    if (deleteTermsAndConditionsErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: deleteTermsAndConditionsErrorMessage,
        onClose: () => dispatch(clearDeleteTermsAndConditionsErrorMessage()),
      });
    }

    if (updateAboutInfoErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: updateAboutInfoErrorMessage,
        onClose: () => dispatch(clearUpdateAboutInfoErrorMessage()),
      });
    }

    if (updateGeneralInfoErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: updateGeneralInfoErrorMessage,
        onClose: () => dispatch(clearUpdateGeneralInfoErrorMessage()),
      });
    }

    if (updateWorkInfoErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: updateWorkInfoErrorMessage,
        onClose: () => dispatch(clearUpdateWorkInfoErrorMessage()),
      });
    }

    if (clientErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: clientErrorMessage,
        onClose: () => dispatch(clearClientErrorMessage()),
      });
    }

    if (restaurantLoginErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: restaurantLoginErrorMessage,
        onClose: () => dispatch(clearRestaurantLoginErrorMessage()),
      });
    }

    if (clientUpdateGeneralInfoErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: clientUpdateGeneralInfoErrorMessage,
        onClose: () => dispatch(clearClientUpdateGeneralInfoErrorMessage()),
      });
    }

    if (fetchArtistAvailabilitiesErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: fetchArtistAvailabilitiesErrorMessage,
        onClose: () => dispatch(clearFetchAvailabilitiesErrorMessage()),
      });
    }

    if (makeBookingErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: makeBookingErrorMessage,
        onClose: () => dispatch(clearMakeBookingErrorMessage()),
      });
    }

    if (cancelBookingByClientErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: cancelBookingByClientErrorMessage,
        onClose: () => dispatch(clearCancelBookingByClientErrorMessage()),
      });
    }

    if (cancelBookingByArtistErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: cancelBookingByArtistErrorMessage,
        onClose: () => dispatch(clearCancelBookingByArtistErrorMessage()),
      });
    }

    if (confirmBookingByArtistErrorMessage) {
      messageApi.error({
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        duration: 5,
        content: confirmBookingByArtistErrorMessage,
        onClose: () => dispatch(clearConfirmBookingByArtistErrorMessage()),
      });
    }
  }, [
    artistLoginErrorMessage,
    createArtistErrorMessage,
    createRestaurantErrorMessage,
    createAddressErrorMessage,
    updateAddressErrorMessage,
    deleteAddressErrorMessage,
    addArtistMediaErrorMessage,
    deleteAddressErrorMessage,
    addTermsAndConditionsErrorMessage,
    deleteTermsAndConditionsErrorMessage,
    updateAboutInfoErrorMessage,
    updateGeneralInfoErrorMessage,
    updateWorkInfoErrorMessage,
    clientErrorMessage,
    restaurantLoginErrorMessage,
    clientUpdateGeneralInfoErrorMessage,
    fetchArtistAvailabilitiesErrorMessage,
    makeBookingErrorMessage,
    cancelBookingByClientErrorMessage,
    cancelBookingByArtistErrorMessage,
    confirmBookingByArtistErrorMessage,
  ]);

  useEffect(() => {
    if (createAddressSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: createAddressSuccessMessage,
        onClose: () => dispatch(clearCreateAddressSuccess()),
      });
    }

    if (updateAddressSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: updateAddressSuccessMessage,
        onClose: () => dispatch(clearUpdateAddressSuccess()),
      });
    }

    if (deleteAddressSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: deleteAddressSuccessMessage,
        onClose: () => dispatch(clearDeleteAddressSuccess()),
      });
    }

    if (addArtistMediaSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: addArtistMediaSuccessMessage,
        onClose: () => dispatch(clearAddArtistMediaSuccessMessage()),
      });
    }

    if (deleteArtistMediaSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: deleteArtistMediaSuccessMessage,
        onClose: () => dispatch(clearDeleteArtistMediaSuccessMessage()),
      });
    }

    if (addTermsAndConditionsSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: addTermsAndConditionsSuccessMessage,
        onClose: () => dispatch(clearAddTermsAndConditionsSuccessMessage()),
      });
    }

    if (deleteTermsAndConditionsSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: deleteTermsAndConditionsSuccessMessage,
        onClose: () => dispatch(clearDeleteTermsAndConditionsSuccessMessage()),
      });
    }

    if (updateAboutInfoSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: updateAboutInfoSuccessMessage,
        onClose: () => dispatch(clearUpdateAboutInfoSuccessMessage()),
      });
    }

    if (updateGeneralInfoSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: updateGeneralInfoSuccessMessage,
        onClose: () => dispatch(clearUpdateGeneralInfoSuccessMessage()),
      });
    }

    if (updateWorkInfoSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: updateWorkInfoSuccessMessage,
        onClose: () => dispatch(clearUpdateWorkInfoSuccessMessage()),
      });
    }

    if (clientUpdateGeneralInfoSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: clientUpdateGeneralInfoSuccessMessage,
        onClose: () => dispatch(clearClientUpdateGeneralInfoSuccessMessage()),
      });
    }

    if (cancelBookingByClientSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: cancelBookingByClientSuccessMessage,
        onClose: () => dispatch(clearCancelBookingByClientSuccessMessage()),
      });
    }

    if (cancelBookingByArtistSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: cancelBookingByArtistSuccessMessage,
        onClose: () => dispatch(clearCancelBookingByArtistSuccessMessage()),
      });
    }

    if (confirmBookingByArtistSuccessMessage) {
      messageApi.success({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        duration: 5,
        content: confirmBookingByArtistSuccessMessage,
        onClose: () => dispatch(clearConfirmBookingByArtistSuccessMessage()),
      });
    }
  }, [
    createAddressSuccessMessage,
    updateAddressSuccessMessage,
    deleteAddressSuccessMessage,
    addArtistMediaSuccessMessage,
    deleteArtistMediaSuccessMessage,
    addTermsAndConditionsSuccessMessage,
    deleteTermsAndConditionsSuccessMessage,
    updateAboutInfoSuccessMessage,
    updateGeneralInfoSuccessMessage,
    updateWorkInfoSuccessMessage,
    clientUpdateGeneralInfoSuccessMessage,
    cancelBookingByClientSuccessMessage,
    cancelBookingByArtistSuccessMessage,
    confirmBookingByArtistSuccessMessage,
  ]);

  return contextHolder;
};

export default Message;
