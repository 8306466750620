import { useNavigate } from "react-router-dom";
import { SmileOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { ROUTES_MAP } from "../../constants/common";

const Events = () => {
  const navigate = useNavigate();

  return (
    <Result
      icon={<SmileOutlined />}
      title="Releasing Soon!"
      extra={
        <Button type="primary" onClick={() => navigate(ROUTES_MAP.HOME.path)}>
          Back Home
        </Button>
      }
    />
  );
};

export default Events;
