import { Fragment, useState } from "react";
import { Button, Col, Divider, Row, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Condition from "./Condition";
import AddConditionModal from "../../Modal/AddCondition";

const TermsAndConditions = ({ termsAndConditions }) => {
  const [
    isAddTermsAndConditionsModalOpen,
    setIsAddTermsAndConditionsModalOpen,
  ] = useState(false);

  const closeAddTermsAndConditionsModalOpen = () => {
    setIsAddTermsAndConditionsModalOpen(false);
  };

  return (
    <Fragment>
      <AddConditionModal
        open={isAddTermsAndConditionsModalOpen}
        closeModal={closeAddTermsAndConditionsModalOpen}
      />
      <Divider orientation="left">
        <Typography.Title level={5}>Terms And Conditions</Typography.Title>
      </Divider>

      <Row gutter={24} justify="space-around">
        {termsAndConditions.map((condition, colIndex) => (
          <Col
            className="flex justify-center mb-8"
            xs={24} // Full width on extra small screens
            sm={24} // Half width on small screens
            md={24} // One-third width on medium screens
            lg={8} // One-fourth width on large screens
            key={colIndex}
          >
            <Condition condition={condition} />
          </Col>
        ))}
      </Row>

      <div className="flex justify-center w-full">
        <Button
          onClick={() => setIsAddTermsAndConditionsModalOpen(true)}
          icon={<PlusOutlined />}
        >
          Add
        </Button>
      </div>
    </Fragment>
  );
};

export default TermsAndConditions;
