import { Fragment, useState } from "react";
import { Button, Card, Divider, Row, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import AboutItem from "../../../../Custom/AboutItem";
import ContactIcon from "../../../../Custom/Icon/ContactIcon";
import UserIcon from "../../../../Custom/Icon/UserIcon";
import EmailIcon from "../../../../Custom/Icon/EmailIcon";
import UpdateGeneralInfoModal from "../../Modal/UpdateGeneralInfo";

const General = ({ client }) => {
  const [isUpdateGeneralInfoModalOpen, setIsUpdateGeneralInfoModalOpen] =
    useState(false);

  const closeUpdateGeneralInfoModalOpen = () => {
    setIsUpdateGeneralInfoModalOpen(false);
  };

  const generalInfoList = [
    {
      label: "Name",
      icon: <UserIcon />,
      value: client.name,
    },
    {
      label: "Email",
      icon: <EmailIcon />,
      value: client.email,
    },
    {
      label: "Contact",
      icon: <ContactIcon />,
      value: client.contact,
    },
    {
      label: "Type",
      icon: <UserIcon />,
      value: client.type.name,
    },
  ];

  return (
    <Fragment>
      <UpdateGeneralInfoModal
        open={isUpdateGeneralInfoModalOpen}
        closeModal={closeUpdateGeneralInfoModalOpen}
      />
      <Divider orientation="left">
        <Typography.Title level={5}>General</Typography.Title>
      </Divider>

      <Row gutter={8}>
        <Card
          className="w-full transition-shadow duration-300 shadow-sm"
          bordered
        >
          {generalInfoList.map((info) => (
            <Card.Grid className="w-full text-left md:w-1/2" hoverable={false}>
              <AboutItem
                icon={info.icon}
                title={info.label}
                content={info.value}
              />
            </Card.Grid>
          ))}
        </Card>
      </Row>

      <div className="flex justify-center w-full pt-8">
        <Button
          onClick={() => setIsUpdateGeneralInfoModalOpen(true)}
          icon={<EditOutlined />}
        >
          Update
        </Button>
      </div>
    </Fragment>
  );
};

export default General;
