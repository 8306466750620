import _ from "lodash";
import moment from "moment";
import commonConstants from "../constants/common";

export const activeKeysFromItems = (items, pathname) =>
  items
    .filter((item) => pathname.includes(`/${item.key}`))
    .map((item) => item.key);

export const createFullName = (user) => {
  // Check if the user object is provided and is not null
  if (!user) {
    return null;
  }

  const name = user.name;

  if (!_.isEmpty(name)) {
    return name;
  }

  // Check if firstName and lastName properties exist and are of type string
  const firstName =
    typeof user.firstName === commonConstants.DATA_TYPES.STRING
      ? user.firstName.trim()
      : "";
  const lastName =
    typeof user.lastName === commonConstants.DATA_TYPES.STRING
      ? user.lastName.trim()
      : "";

  // Return null if both firstName and lastName are empty
  if (!firstName && !lastName) {
    return null;
  }

  // Return the concatenated full name or the one that's present
  const fullName = `${firstName} ${lastName}`;

  return fullName;
};

export const firstLetterCapitalValidator = (label) => (_, value) => {
  if (!value) {
    return Promise.resolve();
  }

  const isFirstCharCapital = value.charAt(0) === value.charAt(0).toUpperCase();

  if (isFirstCharCapital) {
    return Promise.resolve();
  }

  return Promise.reject(
    new Error(`The ${label} must start with a capital letter!`)
  );
};

export const firstLetterCapitalAndSingleWordValidator =
  (label) => (_, value) => {
    if (!value) {
      return Promise.resolve();
    }

    const isFirstCharCapital =
      value.charAt(0) === value.charAt(0).toUpperCase();
    const isSingleWord = value.trim().split(" ").length === 1;

    if (isFirstCharCapital && isSingleWord) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error(
        `The ${label} must start with a capital letter and be a single word!`
      )
    );
  };

export const areAllFieldsInFormFilled = async (form) => {
  try {
    await form.validateFields();
    return true;
  } catch (error) {
    return false;
  }
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return ""; // Handle empty or undefined strings
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const getFinalRating = (reviews) => {
  // If no reviews are present, return the default rating of 4
  if (_.isEmpty(reviews)) {
    return 4;
  }

  // Calculate the average rating
  const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
  const averageRating = totalRating / reviews.length;

  // Possible rating values
  const possibleRatings = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

  // Use lodash's _.minBy to find the closest possible rating to the average rating
  const finalRating = _.minBy(possibleRatings, (rating) =>
    Math.abs(rating - averageRating)
  );

  return finalRating;
};

export const formatHumanReadableDate = (dateString) => {
  // Check if the dateString is valid
  if (!dateString || !moment(dateString, moment.ISO_8601, true).isValid()) {
    return "Invalid date format";
  }

  // Convert and format the date using the desired format
  return moment(dateString).format("Do MMMM, YYYY [at] h:mm A");
};

export const formatHumanReadableDateWithoutTime = (dateString) => {
  // Check if the dateString is valid
  if (!dateString || !moment(dateString, moment.ISO_8601, true).isValid()) {
    return "Invalid date format";
  }

  // Convert and format the date using the desired format
  return moment(dateString).format("Do MMMM, YYYY");
};

export const constructAddress = (address) => {
  if (!address) {
    return "";
  }

  const { houseNumber, streetName, city, pincode, additionalInfo } = address;

  // Initialize an array to hold address components
  const addressComponents = [];

  // Add components conditionally
  if (houseNumber) {
    addressComponents.push(houseNumber);
  }

  if (streetName) {
    addressComponents.push(streetName);
  }

  if (city && city.name) {
    addressComponents.push(city.name);
  }

  // State is now nested within city
  if (city && city.state && city.state.name) {
    addressComponents.push(city.state.name);
  }

  if (pincode) {
    addressComponents.push(`Pincode: ${pincode}`);
  }

  if (additionalInfo) {
    addressComponents.push(`Additional Info: ${additionalInfo}`);
  }

  // Join all valid components with commas
  return addressComponents.length > 0 ? addressComponents.join(", ") : "";
};

export const getGenresString = (array) => {
  if (_.isEmpty(array)) {
    return "";
  }

  return array
    .filter(
      (obj) =>
        obj &&
        (!_.isEmpty(obj?.customGenre) ||
          (obj?.genre && !_.isEmpty(obj?.genre?.name)))
    )
    .map((obj) => obj?.genre?.name || obj.customGenre)
    .join(", ");
};

export const getLanguagesString = (array) => {
  if (_.isEmpty(array)) {
    return "";
  }

  return array
    .filter(
      (obj) =>
        obj &&
        (!_.isEmpty(obj?.customLanguage) ||
          (obj?.language && !_.isEmpty(obj?.language?.name)))
    )
    .map((obj) => obj?.language?.name || obj.customLanguage)
    .join(", ");
};

export const getAwardsString = (array) => {
  if (_.isEmpty(array)) {
    return "";
  }

  return array
    .filter((obj) => obj && obj?.award && !_.isEmpty(obj?.award?.name))
    .map((obj) => obj.award.name)
    .join(", ");
};

export const getEventTypesString = (array) => {
  if (_.isEmpty(array)) {
    return "";
  }

  return array
    .filter((obj) => obj && obj?.eventType && !_.isEmpty(obj?.eventType?.name))
    .map((obj) => obj.eventType.name)
    .join(", ");
};

export const getInstrumentsString = (array) => {
  if (_.isEmpty(array)) {
    return "";
  }

  return array
    .filter(
      (obj) =>
        obj &&
        (!_.isEmpty(obj?.customInstrument) ||
          (obj?.instrument && !_.isEmpty(obj?.instrument?.name)))
    )
    .map((obj) => obj?.instrument?.name || obj.customInstrument)
    .join(", ");
};

export const extractSrcLink = (iframeString) => {
  try {
    // Regular expression to match the src attribute in the iframe string
    const regex = /src="([^"]*)"/;

    // Execute the regex on the input string
    const match = iframeString.match(regex);

    // If a match is found, return the first captured group (the link inside src)
    return match ? match[1] : null;
  } catch (e) {
    return null;
  }
};

/**
 * Formats a number into Indian currency format and appends a rupee symbol.
 * If the number is null or undefined, it returns null.
 *
 * @param {number|null} amount - The number to be formatted.
 * @returns {string|null} - The formatted string with a rupee symbol, or null.
 */
export const formatToRupee = (amount) => {
  if (!amount) {
    return null;
  }

  // Convert the number to Indian number format
  const formattedAmount = new Intl.NumberFormat("en-IN").format(amount);

  // Add the rupee symbol at the end
  return `${formattedAmount} ₹`;
};
