import _ from "lodash";
import { Button, Result } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectLoggedInArtist } from "../../../reducers/artist";
import Artist from "./Profile/Artist";

const MyProfile = () => {
  const navigate = useNavigate();
  const artist = useSelector(selectLoggedInArtist);
  const artistId = artist?.id;

  if (_.isEmpty(artistId)) {
    return (
      <div className="flex justify-center w-full my-10">
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button type="primary" onClick={() => navigate("/")}>
              Back Home
            </Button>
          }
        />
      </div>
    );
  }

  return <Artist artist={artist} />;
};

export default MyProfile;
