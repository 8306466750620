import { createSlice } from "@reduxjs/toolkit";
import * as artistActions from "../actions/artist";
import messageConstants from "../constants/message";

const initialLoadingState = {
  createArtist: false,
  artistLogin: false,
  bookNow: false,
  artists: false,
  artist: false,
  editProfile: false,
  bookings: false,
  fetchBookings: false,
};

const initialErrorState = {
  createArtist: null,
  artistLogin: null,
  bookNow: null,
  artists: null,
  artist: null,
  addArtistMedia: null,
  deleteArtistMedia: null,
  addTermsAndConditions: null,
  deleteTermsAndConditions: null,
  updateAboutInfo: null,
  updateGeneralInfo: null,
  updateWorkInfo: null,
  fetchBookings: null,
  availabilities: null,
  cancelBooking: null,
  confirmBooking: null,
};

const initialSuccessState = {
  createArtist: null,
  artistLogin: null,
  bookNow: null,
  addArtistMedia: null,
  deleteArtistMedia: null,
  addTermsAndConditions: null,
  deleteTermsAndConditions: null,
  updateAboutInfo: null,
  updateGeneralInfo: null,
  updateWorkInfo: null,
  cancelBooking: null,
  confirmBooking: null,
};

export const artistSlice = createSlice({
  name: "artist",
  initialState: {
    artists: [],
    genres: [],
    instruments: [],
    languages: [],
    eventTypes: [],
    artistTypes: [],
    bookings: [],
    availabilities: [],
    city: null,
    artist: null,
    loggedInArtist: null,
    pagination: {
      artists: null,
    },
    loading: initialLoadingState,
    error: initialErrorState,
    success: initialSuccessState,
  },
  reducers: {
    setLoggedInArtist: (state, action) => {
      state.loggedInArtist = action.payload;
    },

    // Actions for setting state values
    clearArtist: (state) => {
      state.artist = null;
    },
    clearLoggedInArtist: (state) => {
      state.loggedInArtist = null;
    },

    // Actions for setting error messages
    setAddArtistMediaErrorMessage: (state, action) => {
      state.error.addArtistMedia = action.payload;
    },

    // Actions for clearing error messages
    clearArtistLoginErrorMessage: (state) => {
      state.error.artistLogin = null;
    },
    clearCreateArtistErrorMessage: (state) => {
      state.error.createArtist = null;
    },
    clearAddArtistMediaErrorMessage: (state) => {
      state.error.addArtistMedia = null;
    },
    clearDeleteArtistMediaErrorMessage: (state) => {
      state.error.deleteArtistMedia = null;
    },
    clearAddTermsAndConditionsErrorMessage: (state) => {
      state.error.addTermsAndConditions = null;
    },
    clearDeleteTermsAndConditionsErrorMessage: (state) => {
      state.error.deleteTermsAndConditions = null;
    },
    clearUpdateAboutInfoErrorMessage: (state) => {
      state.error.updateAboutInfo = null;
    },
    clearUpdateGeneralInfoErrorMessage: (state) => {
      state.error.updateGeneralInfo = null;
    },
    clearUpdateWorkInfoErrorMessage: (state) => {
      state.error.updateWorkInfo = null;
    },
    clearFetchBookingsErrorMessage: (state) => {
      state.error.fetchBookings = null;
    },
    clearFetchAvailabilitiesErrorMessage: (state) => {
      state.error.availabilities = null;
    },
    clearCancelBookingByArtistErrorMessage: (state) => {
      state.error.cancelBooking = null;
    },
    clearConfirmBookingByArtistErrorMessage: (state) => {
      state.error.confirmBooking = null;
    },

    // Actions for clearing success messages
    clearArtistLoginSuccessMessage: (state) => {
      state.success.artistLogin = null;
    },
    clearCreateArtistSuccessMessage: (state) => {
      state.success.createArtist = null;
    },
    clearAddArtistMediaSuccessMessage: (state) => {
      state.success.addArtistMedia = null;
    },
    clearDeleteArtistMediaSuccessMessage: (state) => {
      state.success.deleteArtistMedia = null;
    },
    clearAddTermsAndConditionsSuccessMessage: (state) => {
      state.success.addTermsAndConditions = null;
    },
    clearDeleteTermsAndConditionsSuccessMessage: (state) => {
      state.success.deleteTermsAndConditions = null;
    },
    clearUpdateAboutInfoSuccessMessage: (state) => {
      state.success.updateAboutInfo = null;
    },
    clearUpdateGeneralInfoSuccessMessage: (state) => {
      state.success.updateGeneralInfo = null;
    },
    clearUpdateWorkInfoSuccessMessage: (state) => {
      state.success.updateWorkInfo = null;
    },
    clearCancelBookingByArtistSuccessMessage: (state) => {
      state.success.cancelBooking = null;
    },
    clearConfirmBookingByArtistSuccessMessage: (state) => {
      state.success.confirmBooking = null;
    },
  },
  extraReducers(builder) {
    builder
      // Creating artist profile actions
      .addCase(artistActions.createArtist.pending, (state) => {
        state.loading.createArtist = true;
      })
      .addCase(artistActions.createArtist.fulfilled, (state) => {
        state.loading.createArtist = false;
        state.success.createArtist =
          messageConstants.DEFAULT_SUCCESS.CREATE_ARTIST;
      })
      .addCase(artistActions.createArtist.rejected, (state, action) => {
        state.loading.createArtist = false;
        state.error.createArtist =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.CREATE_ARTIST;
      })

      // Artist login actions
      .addCase(artistActions.artistLogin.pending, (state) => {
        state.loading.artistLogin = true;
      })
      .addCase(artistActions.artistLogin.fulfilled, (state, action) => {
        state.loading.artistLogin = false;
        state.loggedInArtist = action?.payload?.artist;
        state.success.artistLogin =
          messageConstants.DEFAULT_SUCCESS.ARTIST_LOGIN;
      })
      .addCase(artistActions.artistLogin.rejected, (state, action) => {
        state.loading.artistLogin = false;
        state.loggedInArtist = null;
        state.error.artistLogin =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.ARTIST_LOGIN;
      })

      // Fetching Artists Action
      .addCase(artistActions.fetchArtists.pending, (state) => {
        state.loading.artists = true;
      })
      .addCase(artistActions.fetchArtists.fulfilled, (state, action) => {
        state.loading.artists = false;
        state.artists = action.payload?.artists;
        state.city = action.payload?.city;
        state.pagination.artists = action.payload?.pagination;
      })
      .addCase(artistActions.fetchArtists.rejected, (state, action) => {
        state.loading.artists = false;
        state.artists = [];
        state.error.artists =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.FETCH_ARTISTS_BY_CITY_ID;
      })

      // Fetching an artist action
      .addCase(artistActions.fetchLoggedInArtist.pending, (state) => {
        state.loading.artist = true;
      })
      .addCase(artistActions.fetchLoggedInArtist.fulfilled, (state, action) => {
        state.loading.artist = false;
        state.loggedInArtist = action.payload?.artist;
      })
      .addCase(artistActions.fetchLoggedInArtist.rejected, (state, action) => {
        state.loading.artist = false;
        state.error.artist =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.FETCH_ARTIST_BY_ID;
      })

      // Fetching an artist action
      .addCase(artistActions.fetchArtistById.pending, (state) => {
        state.loading.artist = true;
      })
      .addCase(artistActions.fetchArtistById.fulfilled, (state, action) => {
        state.loading.artist = false;
        state.artist = action.payload?.artist;
        state.error.artist = null;
      })
      .addCase(artistActions.fetchArtistById.rejected, (state, action) => {
        state.loading.artist = false;
        state.artist = null;
        state.error.artist =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.FETCH_ARTIST_BY_ID;
      })
      .addCase(artistActions.addArtistMedia.pending, (state) => {
        state.loading.editProfile = true;
      })
      .addCase(artistActions.addArtistMedia.fulfilled, (state, action) => {
        state.loading.editProfile = false;
        state.success.addArtistMedia =
          messageConstants.DEFAULT_SUCCESS.ADD_ARTIST_MEDIA;
      })
      .addCase(artistActions.addArtistMedia.rejected, (state, action) => {
        state.loading.editProfile = false;
        state.error.addArtistMedia =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.ADD_ARTIST_MEDIA;
      })
      .addCase(artistActions.deleteArtistMedia.pending, (state) => {
        state.loading.editProfile = true;
      })
      .addCase(artistActions.deleteArtistMedia.fulfilled, (state, action) => {
        state.loading.editProfile = false;
        state.success.deleteArtistMedia =
          messageConstants.DEFAULT_SUCCESS.DELETE_ARTIST_MEDIA;
      })
      .addCase(artistActions.deleteArtistMedia.rejected, (state, action) => {
        state.loading.editProfile = false;
        state.error.deleteArtistMedia =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.DELETE_ARTIST_MEDIA;
      })
      .addCase(artistActions.addTermsAndConditions.pending, (state) => {
        state.loading.editProfile = true;
      })
      .addCase(
        artistActions.addTermsAndConditions.fulfilled,
        (state, action) => {
          state.loading.editProfile = false;
          state.success.addTermsAndConditions =
            messageConstants.DEFAULT_SUCCESS.ADD_TERMS_AND_CONDITIONS;
        }
      )
      .addCase(
        artistActions.addTermsAndConditions.rejected,
        (state, action) => {
          state.loading.editProfile = false;
          state.error.addTermsAndConditions =
            action?.payload?.message ||
            messageConstants.DEFAULT_ERROR.ADD_TERMS_AND_CONDITIONS;
        }
      )
      .addCase(artistActions.deleteTermsAndConditions.pending, (state) => {
        state.loading.editProfile = true;
      })
      .addCase(
        artistActions.deleteTermsAndConditions.fulfilled,
        (state, action) => {
          state.loading.editProfile = false;
          state.success.deleteTermsAndConditions =
            messageConstants.DEFAULT_SUCCESS.DELETE_TERMS_AND_CONDITIONS;
        }
      )
      .addCase(
        artistActions.deleteTermsAndConditions.rejected,
        (state, action) => {
          state.loading.editProfile = false;
          state.error.deleteTermsAndConditions =
            action?.payload?.message ||
            messageConstants.DEFAULT_ERROR.DELETE_TERMS_AND_CONDITIONS;
        }
      )

      // Updating About Section
      .addCase(artistActions.updateAboutInfo.pending, (state) => {
        state.loading.editProfile = true;
      })
      .addCase(artistActions.updateAboutInfo.fulfilled, (state) => {
        state.loading.editProfile = false;
        state.success.updateAboutInfo =
          messageConstants.DEFAULT_SUCCESS.UPDATE_ABOUT_INFO;
      })
      .addCase(artistActions.updateAboutInfo.rejected, (state, action) => {
        state.loading.editProfile = false;
        state.error.updateAboutInfo =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.UPDATE_ABOUT_INFO;
      })

      // Updating General Information
      .addCase(artistActions.updateGeneralInfo.pending, (state) => {
        state.loading.editProfile = true;
      })
      .addCase(artistActions.updateGeneralInfo.fulfilled, (state) => {
        state.loading.editProfile = false;
        state.success = {
          ...initialSuccessState,
          updateGeneralInfo:
            messageConstants.DEFAULT_SUCCESS.UPDATE_GENERAL_INFO,
        };
      })
      .addCase(artistActions.updateGeneralInfo.rejected, (state, action) => {
        state.loading.editProfile = false;
        state.error = {
          ...initialErrorState,
          updateGeneralInfo:
            action?.payload?.message ||
            messageConstants.DEFAULT_ERROR.UPDATE_GENERAL_INFO,
        };
      })

      // Updating Profile Picture
      .addCase(artistActions.updateProfilePicture.pending, (state) => {
        state.loading.editProfile = true;
      })
      .addCase(
        artistActions.updateProfilePicture.fulfilled,
        (state, action) => {
          state.loading.editProfile = false;
          state.success.updateGeneralInfo =
            action?.payload?.message ||
            messageConstants.DEFAULT_ERROR.UPDATE_GENERAL_INFO;
        }
      )
      .addCase(artistActions.updateProfilePicture.rejected, (state, action) => {
        state.loading.editProfile = false;
        state.error.updateGeneralInfo =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.UPDATE_GENERAL_INFO;
      })

      // Updating Work Information
      .addCase(artistActions.updateWorkInfo.pending, (state) => {
        state.loading.editProfile = true;
      })
      .addCase(artistActions.updateWorkInfo.fulfilled, (state) => {
        state.loading.editProfile = false;
        state.success = {
          ...initialSuccessState,
          updateWorkInfo: messageConstants.DEFAULT_SUCCESS.UPDATE_WORK_INFO,
        };
      })
      .addCase(artistActions.updateWorkInfo.rejected, (state, action) => {
        state.loading.editProfile = false;
        state.error = {
          ...initialErrorState,
          updateWorkInfo:
            action?.payload?.message ||
            messageConstants.DEFAULT_ERROR.UPDATE_WORK_INFO,
        };
      })

      // Fetching Metadata
      .addCase(artistActions.fetchMetadata.pending, (state) => {
        state.loading.editProfile = true;
      })
      .addCase(artistActions.fetchMetadata.fulfilled, (state, action) => {
        state.loading.editProfile = false;
        state.genres = action.payload?.genres;
        state.instruments = action.payload?.instruments;
        state.eventTypes = action.payload?.eventTypes;
        state.languages = action.payload?.languages;
        state.artistTypes = action.payload?.artistTypes;
      })
      .addCase(artistActions.fetchMetadata.rejected, (state) => {
        state.loading.editProfile = false;
      })

      // Fetching Bookings
      .addCase(artistActions.fetchBookingsByArtistId.pending, (state) => {
        state.loading.fetchBookings = true;
        state.error.fetchBookings = null;
      })
      .addCase(
        artistActions.fetchBookingsByArtistId.fulfilled,
        (state, action) => {
          state.loading.fetchBookings = false;
          state.bookings = action?.payload?.bookings;
        }
      )
      .addCase(
        artistActions.fetchBookingsByArtistId.rejected,
        (state, action) => {
          state.loading.fetchBookings = false;
          state.error.fetchBookings =
            action?.payload?.message ||
            messageConstants.DEFAULT_ERROR.FETCH_BOOKINGS;
        }
      )
      .addCase(artistActions.fetchAvailabilityByArtistId.pending, (state) => {
        state.loading.availabilities = true;
        state.availabilities = [];
      })
      .addCase(
        artistActions.fetchAvailabilityByArtistId.fulfilled,
        (state, action) => {
          state.loading.availabilities = false;
          state.availabilities = action?.payload?.availabilities;
        }
      )
      .addCase(
        artistActions.fetchAvailabilityByArtistId.rejected,
        (state, action) => {
          state.loading.availabilities = false;
          state.error.availabilities =
            action?.payload?.message ||
            messageConstants.DEFAULT_ERROR.FETCH_AVAILABILITIES;
        }
      )
      // Cancel Booking
      .addCase(artistActions.cancelBookingById.pending, (state) => {
        state.loading.fetchBookings = true;
      })
      .addCase(artistActions.cancelBookingById.fulfilled, (state) => {
        state.loading.fetchBookings = false;
        state.success.cancelBooking =
          messageConstants.DEFAULT_SUCCESS.CANCEL_BOOKING;
      })
      .addCase(artistActions.cancelBookingById.rejected, (state, action) => {
        state.loading.fetchBookings = false;
        state.error.cancelBooking =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.CANCEL_BOOKING;
      })
      // Confirm Booking
      .addCase(artistActions.confirmBookingById.pending, (state) => {
        state.loading.fetchBookings = true;
      })
      .addCase(artistActions.confirmBookingById.fulfilled, (state) => {
        state.loading.fetchBookings = false;
        state.success.confirmBooking =
          messageConstants.DEFAULT_SUCCESS.CONFIRM_BOOKING;
      })
      .addCase(artistActions.confirmBookingById.rejected, (state, action) => {
        state.loading.fetchBookings = false;
        state.error.confirmBooking =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.CONFIRM_BOOKING;
      });
  },
});

export const selectArtists = (state) => state.artist.artists;
export const selectGenres = (state) => state.artist.genres;
export const selectInstruments = (state) => state.artist.instruments;
export const selectLanguages = (state) => state.artist.languages;
export const selectEventTypes = (state) => state.artist.eventTypes;
export const selectArtistTypes = (state) => state.artist.artistTypes;
export const selectCity = (state) => state.artist.city;
export const selectArtist = (state) => state.artist.artist;
export const selectArtistBookings = (state) => state.artist.bookings;
export const selectArtistAvailabilities = (state) =>
  state.artist.availabilities;
export const selectLoggedInArtist = (state) => state.artist.loggedInArtist;

// Pagination States
export const selectArtistsPagination = (state) =>
  state.artist.pagination.artists;

// Loading States
export const selectCreateArtistLoading = (state) =>
  state.artist.loading.createArtist;
export const selectArtistLoginLoading = (state) =>
  state.artist.loading.artistLogin;
export const selectBookNowLoading = (state) => state.artist.loading.bookNow;
export const selectArtistsLoading = (state) => state.artist.loading.artists;
export const selectArtistLoading = (state) => state.artist.loading.artist;
export const selectEditProfileLoading = (state) =>
  state.artist.loading.editProfile;
export const selectArtistAvailabilitiesLoading = (state) =>
  state.artist.loading.availabilities;
export const selectFetchBookingsLoading = (state) =>
  state.artist.loading.fetchBookings;

// Error Messages
export const selectCreateArtistError = (state) =>
  state.artist.error.createArtist;
export const selectArtistLoginError = (state) => state.artist.error.artistLogin;
export const selectArtistError = (state) => state.artist.error.artist;
export const selectAddArtistMediaErrorMessage = (state) =>
  state.artist.error.addArtistMedia;
export const selectDeleteArtistMediaErrorMessage = (state) =>
  state.artist.error.deleteArtistMedia;
export const selectAddTermsAndConditionsErrorMessage = (state) =>
  state.artist.error.addTermsAndConditions;
export const selectDeleteTermsAndConditionsErrorMessage = (state) =>
  state.artist.error.deleteTermsAndConditions;
export const selectUpdateAboutInfoErrorMessage = (state) =>
  state.artist.error.updateAboutInfo;
export const selectUpdateGeneralInfoErrorMessage = (state) =>
  state.artist.error.updateGeneralInfo;
export const selectUpdateWorkInfoErrorMessage = (state) =>
  state.artist.error.updateWorkInfo;
export const selectFetchBookingsErrorMessage = (state) =>
  state.artist.error.fetchBookings;
export const selectFetchAvailabilitiesErrorMessage = (state) =>
  state.artist.error.availabilities;
export const selectCancelBookingByArtistErrorMessage = (state) =>
  state.artist.error.cancelBooking;
export const selectConfirmBookingByArtistErrorMessage = (state) =>
  state.artist.error.confirmBooking;

// Success Messages
export const selectCreateArtistSuccessMessage = (state) =>
  state.artist.success.createArtist;
export const selectAddArtistMediaSuccessMessage = (state) =>
  state.artist.success.addArtistMedia;
export const selectDeleteArtistMediaSuccessMessage = (state) =>
  state.artist.success.deleteArtistMedia;
export const selectAddTermsAndConditionsSuccessMessage = (state) =>
  state.artist.success.addTermsAndConditions;
export const selectDeleteTermsAndConditionsSuccessMessage = (state) =>
  state.artist.success.deleteTermsAndConditions;
export const selectUpdateAboutInfoSuccessMessage = (state) =>
  state.artist.success.updateAboutInfo;
export const selectUpdateGeneralInfoSuccessMessage = (state) =>
  state.artist.success.updateGeneralInfo;
export const selectUpdateWorkInfoSuccessMessage = (state) =>
  state.artist.success.updateWorkInfo;
export const selectCancelBookingByArtistSuccessMessage = (state) =>
  state.artist.success.cancelBooking;
export const selectConfirmBookingByArtistSuccessMessage = (state) =>
  state.artist.success.confirmBooking;

export const {
  setLoggedInArtist,
  // Export actions for clearing state values
  clearArtist,
  clearLoggedInArtist,

  // Export actions for setting error messages
  setAddArtistMediaErrorMessage,

  // Export actions for clearing error messages
  clearCreateArtistErrorMessage,
  clearArtistLoginErrorMessage,
  clearAddArtistMediaErrorMessage,
  clearDeleteArtistMediaErrorMessage,
  clearAddTermsAndConditionsErrorMessage,
  clearDeleteTermsAndConditionsErrorMessage,
  clearUpdateAboutInfoErrorMessage,
  clearUpdateGeneralInfoErrorMessage,
  clearUpdateWorkInfoErrorMessage,
  clearFetchBookingsErrorMessage,
  clearFetchAvailabilitiesErrorMessage,
  clearCancelBookingByArtistErrorMessage,
  clearConfirmBookingByArtistErrorMessage,

  // Export actions for clearing success messages
  clearCreateArtistSuccessMessage,
  clearArtistLoginSuccess,
  clearAddArtistMediaSuccessMessage,
  clearDeleteArtistMediaSuccessMessage,
  clearAddTermsAndConditionsSuccessMessage,
  clearDeleteTermsAndConditionsSuccessMessage,
  clearUpdateAboutInfoSuccessMessage,
  clearUpdateGeneralInfoSuccessMessage,
  clearUpdateWorkInfoSuccessMessage,
  clearCancelBookingByArtistSuccessMessage,
  clearConfirmBookingByArtistSuccessMessage,
} = artistSlice.actions;

export default artistSlice.reducer;
