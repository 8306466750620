import { useNavigate } from "react-router-dom";
import { Divider, Modal, Typography } from "antd";
import { ROUTES_MAP } from "../../../constants/common";
import guitar from "../../../images/icons/guitar.png";
import client from "../../../images/icons/client.png";
import restaurant from "../../../images/icons/restaurant.png";

const LoginModal = ({ isLogin, isModalOpen, closeModal }) => {
  const navigate = useNavigate();

  const onArtistSignup = () => {
    closeModal();
    navigate(ROUTES_MAP.ARTIST_SIGNUP.path);
  };

  const onRestaurantSignup = () => {
    closeModal();
    navigate(ROUTES_MAP.RESTAURANT_SIGNUP.path);
  };

  const onIndividualSignup = () => {
    closeModal();
    navigate(ROUTES_MAP.INDIVIDUAL_SIGNUP.path);
  };

  const onArtistLogin = () => {
    closeModal();
    navigate(ROUTES_MAP.ARTIST_LOGIN.path);
  };

  const onRestaurantLogin = () => {
    closeModal();
    navigate(ROUTES_MAP.RESTAURANT_LOGIN.path);
  };

  const onIndividualLogin = () => {
    closeModal();
    navigate(ROUTES_MAP.INDIVIDUAL_LOGIN.path);
  };

  return (
    <Modal
      title={isLogin ? "Login" : "Signup"}
      open={isModalOpen}
      onCancel={closeModal}
      footer={null}
      centered
    >
      <div className="flex justify-center">
        <div
          className="flex flex-col items-center gap-2 p-5 rounded-lg hover:shadow-xl hover:cursor-pointer"
          onClick={isLogin ? onArtistLogin : onArtistSignup}
        >
          <img className="w-[80px]" src={guitar} alt="Artist" />
          <Typography.Paragraph className="m-0 text-center">
            I'm an Artist
          </Typography.Paragraph>
        </div>
      </div>
      <Divider orientation="left">
        <Typography.Paragraph className="m-0" strong>
          Are you looking to hire?
        </Typography.Paragraph>
      </Divider>
      <div className="flex justify-center pb-4">
        <div className="flex gap-12">
          <div
            className="flex flex-col items-center gap-2 p-5 rounded-lg hover:shadow-xl hover:cursor-pointer"
            onClick={isLogin ? onRestaurantLogin : onRestaurantSignup}
          >
            <img className="w-[80px]" src={restaurant} alt="Restaurant" />
            <Typography.Paragraph className="m-0 text-center">
              I'm a Restaurant
            </Typography.Paragraph>
          </div>
          <div
            className="flex flex-col items-center gap-2 p-5 rounded-lg hover:shadow-xl hover:cursor-pointer"
            onClick={isLogin ? onIndividualLogin : onIndividualSignup}
          >
            <img className="w-[80px]" src={client} alt="Individual" />
            <Typography.Paragraph className="m-0 text-center">
              I'm an Individual
            </Typography.Paragraph>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
