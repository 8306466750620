import { createAsyncThunk } from "@reduxjs/toolkit";
import * as artistAPIs from "../api/artist";

export const createArtist = createAsyncThunk(
  "artist/createArtist",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await artistAPIs.createArtist(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const artistLogin = createAsyncThunk(
  "artist/artistLogin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await artistAPIs.artistLogin(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchArtists = createAsyncThunk(
  "artist/fetchArtists",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await artistAPIs.fetchArtists(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchLoggedInArtist = createAsyncThunk(
  "artist/fetchLoggedInArtist",
  async (id, { rejectWithValue }) => {
    try {
      const response = await artistAPIs.fetchArtistById(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchArtistById = createAsyncThunk(
  "artist/fetchArtistById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await artistAPIs.fetchArtistById(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchBookingsByArtistId = createAsyncThunk(
  "artist/fetchBookingsByArtistId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await artistAPIs.fetchBookingsByArtistId(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const cancelBookingById = createAsyncThunk(
  "artist/cancelBookingById",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await artistAPIs.cancelBookingById(payload?.bookingId);
      dispatch(fetchBookingsByArtistId(payload?.artistId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const confirmBookingById = createAsyncThunk(
  "artist/confirmBookingById",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await artistAPIs.confirmBookingById(payload?.bookingId);
      dispatch(fetchBookingsByArtistId(payload?.artistId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAvailabilityByArtistId = createAsyncThunk(
  "artist/fetchAvailabilityByArtistId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await artistAPIs.fetchAvailabilityByArtistId(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addArtistMedia = createAsyncThunk(
  "artist/addArtistMedia",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await artistAPIs.addArtistMedia(payload);
      dispatch(fetchLoggedInArtist(payload?.artistId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteArtistMedia = createAsyncThunk(
  "artist/deleteArtistMedia",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await artistAPIs.deleteArtistMedia(payload?.mediaId);
      dispatch(fetchLoggedInArtist(payload?.artistId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addTermsAndConditions = createAsyncThunk(
  "artist/addTermsAndConditions",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await artistAPIs.addTermsAndConditions(payload);
      dispatch(fetchLoggedInArtist(payload?.artistId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteTermsAndConditions = createAsyncThunk(
  "artist/deleteTermsAndConditions",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await artistAPIs.deleteTermsAndConditions(
        payload?.termsAndConditionsId
      );
      dispatch(fetchLoggedInArtist(payload?.artistId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateAboutInfo = createAsyncThunk(
  "artist/updateAboutInfo",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await artistAPIs.updateAboutInfo(payload);
      dispatch(fetchLoggedInArtist(payload?.artistId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateProfilePicture = createAsyncThunk(
  "artist/updateProfilePicture",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await artistAPIs.updateProfilePicture(
        payload?.artistId,
        payload?.payload
      );
      dispatch(fetchLoggedInArtist(payload?.artistId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateGeneralInfo = createAsyncThunk(
  "artist/updateGeneralInfo",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await artistAPIs.updateGeneralInfo(payload);
      dispatch(fetchLoggedInArtist(payload?.artistId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateWorkInfo = createAsyncThunk(
  "artist/updateWorkInfo",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await artistAPIs.updateWorkInfo(payload);
      dispatch(fetchLoggedInArtist(payload?.artistId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchMetadata = createAsyncThunk(
  "artist/fetchMetadata",
  async (_, { rejectWithValue }) => {
    try {
      const response = await artistAPIs.fetchMetadata();
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
