import _ from "lodash";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, Space, Typography } from "antd";
import {
  EditOutlined,
  ExclamationCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { constructAddress } from "../../utils/common";
import {
  deleteArtistAddress,
  deleteClientAddress,
} from "../../actions/address";
import EditAddressModal from "./Modal/EditAddress";

const Address = ({ address }) => {
  const dispatch = useDispatch();
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);

  const handleEditAddressModalClose = () => {
    setIsEditAddressModalOpen(false);
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: "Are you sure delete this address?",
      icon: <ExclamationCircleFilled />,
      content:
        "This will delete the address permanently. You will have to add an address to show that you are available to the clients.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        if (!_.isEmpty(address.artistId)) {
          dispatch(
            deleteArtistAddress({
              artistId: address.artistId,
              addressId: address.id,
            })
          );
        } else {
          dispatch(
            deleteClientAddress({
              clientId: address.clientId,
              addressId: address.id,
            })
          );
        }
      },
      onCancel() {},
    });
  };

  return (
    <Fragment>
      <EditAddressModal
        open={isEditAddressModalOpen}
        closeModal={handleEditAddressModalClose}
        address={address}
      />
      <div className="w-full p-6 rounded-md shadow">
        <Typography.Title level={5}>{address.addressName}</Typography.Title>
        {constructAddress(address)}

        <div className="flex justify-end w-full pt-6">
          <Space size="middle">
            <Button
              onClick={() => setIsEditAddressModalOpen(true)}
              icon={<EditOutlined />}
            />
            <Button
              onClick={showDeleteConfirm}
              icon={<DeleteOutlined />}
              danger
            />
          </Space>
        </div>
      </div>
    </Fragment>
  );
};

export default Address;
