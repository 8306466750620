import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SignupForm from "./SignupForm";
import {
  clearCreateRestaurantSuccess,
  selectCreateRestaurantLoading,
  selectCreateRestaurantSuccess,
} from "../../../../reducers/client";
import restaurant from "../../../../images/icons/restaurant.png";

const RestaurantSignup = () => {
  const dispatch = useDispatch();
  const createRestaurantLoading = useSelector(selectCreateRestaurantLoading);
  const createRestaurantSuccess = useSelector(selectCreateRestaurantSuccess);

  return (
    <div className="flex flex-row justify-center w-full">
      <div className="w-full min-h-[90vh] pt-4 flex md:flex-row flex-col justify-center items-center gap-6">
        <div className="flex flex-col items-center w-full gap-4 px-8 md:w-1/3 md:px-0 md:justify-center">
          <img
            className="w-[100px] h-[100px]"
            src={restaurant}
            alt="Restaurant"
          />
          <Typography.Title className="font-extrabold font-[Montserrat] m-0 text-center">
            Create Restaurant Account
          </Typography.Title>
          <Typography.Paragraph className="w-full m-0 text-center text-slate-400">
            Connect with talented artists all over India to bring your
            restaurant to life! Make your customers come back again with the
            artists from our talent pool.
          </Typography.Paragraph>
        </div>
        <div className="flex flex-col items-center justify-center w-full md:w-1/2">
          <div className="w-2/3">
            {createRestaurantLoading ? (
              <div className="w-full text-center">
                <Spin indicator={<LoadingOutlined spin />} size="large" />
              </div>
            ) : createRestaurantSuccess ? (
              <Alert
                message="Success!"
                showIcon
                description="Your account has been registered. Our representative will reach out to you and verify your profile offline. Thank you for your interest in The Artist Network!"
                type="success"
                closable
                onClose={() => dispatch(clearCreateRestaurantSuccess())}
              />
            ) : (
              <SignupForm />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantSignup;
