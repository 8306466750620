import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTES_MAP } from "./constants/common";
import * as authAPIs from "./api/auth";
import Home from "./components/Home";
import Events from "./components/Events";
import HireArtist from "./components/Hire";
import Artist from "./components/Artist/MyProfile/Profile";
import Client from "./components/Client";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Addresses from "./components/MyAddresses";
import Message from "./components/Message";
import ArtistMyProfile from "./components/Artist/MyProfile";
import ArtistEditProfile from "./components/Artist/MyProfile/Edit";
import ArtistLogin from "./components/Artist/Login";
import ArtistSignup from "./components/Artist/SignUp";
import ArtistBookings from "./components/Artist/Bookings";

import RestaurantLogin from "./components/Client/Restaurant/Login";
import RestaurantSignup from "./components/Client/Restaurant/SignUp";
import IndividualLogin from "./components/Client/Individual/Login";
import IndividualSignup from "./components/Client/Individual/SignUp";
import ClientEditProfile from "./components/Client/MyProfile/Edit";
import ClientBookings from "./components/Client/Bookings";
import Contact from "./components/Contact";
import { setLoggedInArtist } from "./reducers/artist";
import { setLoggedInClient } from "./reducers/client";
import { useDispatch } from "react-redux";
import RefundAndCancellation from "./components/RefundAndCancellation";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Blogs from "./components/Blogs";
import TermsAndConditions from "./components/TermsAndConditions";
import FAQ from "./components/FAQ";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Check if the user is logged in when the app starts
    const checkUserSession = async () => {
      try {
        const response = await authAPIs.validateToken();
        const { data } = response ?? {};

        if (data.userType === "ARTIST") {
          dispatch(setLoggedInArtist(data.artist));
        } else {
          dispatch(setLoggedInClient(data.client));
        }
      } catch (error) {}
    };

    checkUserSession();
  }, []);

  return (
    <div>
      <Message />
      <Navbar />
      <Routes>
        <Route path={ROUTES_MAP.HOME.path} element={<Home />} />
        <Route path={ROUTES_MAP.EVENTS.path} element={<Events />} />
        <Route path={ROUTES_MAP.CONTACT.path} element={<Contact />} />
        <Route path={ROUTES_MAP.ARTISTS.path} element={<HireArtist />} />
        // Artist Routes
        <Route path={`${ROUTES_MAP.ARTIST.path}/:id`} element={<Artist />} />
        <Route
          path={ROUTES_MAP.ARTIST_MY_PROFILE.path}
          element={<ArtistMyProfile />}
        />
        <Route
          path={ROUTES_MAP.ARTIST_EDIT_PROFILE.path}
          element={<ArtistEditProfile />}
        />
        <Route
          path={ROUTES_MAP.ARTIST_BOOKINGS.path}
          element={<ArtistBookings />}
        />
        <Route
          path={ROUTES_MAP.ARTIST_ADDRESSES.path}
          element={<Addresses />}
        />
        <Route path={ROUTES_MAP.ARTIST_LOGIN.path} element={<ArtistLogin />} />
        <Route
          path={ROUTES_MAP.ARTIST_SIGNUP.path}
          element={<ArtistSignup />}
        />
        // Client Routes
        <Route
          path={ROUTES_MAP.RESTAURANT_LOGIN.path}
          element={<RestaurantLogin />}
        />
        <Route
          path={ROUTES_MAP.RESTAURANT_SIGNUP.path}
          element={<RestaurantSignup />}
        />
        <Route
          path={ROUTES_MAP.INDIVIDUAL_LOGIN.path}
          element={<IndividualLogin />}
        />
        <Route
          path={ROUTES_MAP.INDIVIDUAL_SIGNUP.path}
          element={<IndividualSignup />}
        />
        <Route
          path={ROUTES_MAP.CLIENT_ADDRESSES.path}
          element={<Addresses />}
        />
        <Route
          path={ROUTES_MAP.CLIENT_BOOKINGS.path}
          element={<ClientBookings />}
        />
        <Route
          path={ROUTES_MAP.CLIENT_EDIT_PROFILE.path}
          element={<ClientEditProfile />}
        />
        // Footer Routes
        <Route path={ROUTES_MAP.FAQ.path} element={<FAQ />} />
        <Route path={ROUTES_MAP.BLOGS.path} element={<Blogs />} />
        <Route
          path={ROUTES_MAP.PRIVACY_POLICY.path}
          element={<PrivacyPolicy />}
        />
        <Route
          path={ROUTES_MAP.REFUND_AND_CANCELLATION.path}
          element={<RefundAndCancellation />}
        />
        <Route
          path={ROUTES_MAP.TERMS_AND_CONDITIONS.path}
          element={<TermsAndConditions />}
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
