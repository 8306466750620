import { useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { deleteArtistMedia } from "../../../../../actions/artist";

const Video = ({ media }) => {
  const dispatch = useDispatch();

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: "Are you sure delete this video?",
      icon: <ExclamationCircleFilled />,
      content:
        "This will delete the video permanently. You will have to add a video to showcase your portfolio to the clients.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        dispatch(
          deleteArtistMedia({
            artistId: media.artistId,
            mediaId: media.id,
          })
        );
      },
      onCancel() {},
    });
  };

  return (
    <div className="w-full rounded-md shadow">
      <div
        direction="vertical"
        size="small"
        className="relative w-full"
        style={{ paddingBottom: "56.25%" }}
      >
        <iframe
          className="absolute top-0 left-0 w-full h-full rounded-md"
          src={media.link}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </div>

      <div className="flex justify-end w-full pt-6 pb-6 pr-6">
        <Button onClick={showDeleteConfirm} icon={<DeleteOutlined />} danger>
          Delete
        </Button>
      </div>
    </div>
  );
};

export default Video;
