import { Fragment, useState } from "react";
import { Button, Col, Divider, Row, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Video from "./Video";
import AddArtistMediaModal from "../../Modal/AddArtistMedia";

const Gallery = ({ medias }) => {
  const [isAddArtistMediaModalOpen, setIsAddArtistMediaModalOpen] =
    useState(false);

  const closeAddArtistMediaModal = () => {
    setIsAddArtistMediaModalOpen(false);
  };

  return (
    <Fragment>
      <AddArtistMediaModal
        open={isAddArtistMediaModalOpen}
        closeModal={closeAddArtistMediaModal}
      />
      <Divider orientation="left">
        <Typography.Title level={5}>Gallery</Typography.Title>
      </Divider>

      <Row gutter={24} justify="space-around">
        {medias.map((media, colIndex) => (
          <Col
            className="flex justify-center mb-8"
            xs={24} // Full width on extra small screens
            sm={12} // Half width on small screens
            md={12} // One-third width on medium screens
            lg={8} // One-fourth width on large screens
            key={colIndex}
          >
            <Video media={media} />
          </Col>
        ))}
      </Row>

      <div className="flex justify-center w-full">
        <Button
          onClick={() => setIsAddArtistMediaModalOpen(true)}
          icon={<PlusOutlined />}
        >
          Add
        </Button>
      </div>
    </Fragment>
  );
};

export default Gallery;
