import { createAsyncThunk } from "@reduxjs/toolkit";
import * as clientAPIs from "../api/client";

export const createRestaurant = createAsyncThunk(
  "client/createRestaurant",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await clientAPIs.createRestaurant(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const restaurantLogin = createAsyncThunk(
  "client/restaurantLogin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await clientAPIs.restaurantLogin(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createIndividual = createAsyncThunk(
  "client/createIndividual",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await clientAPIs.createIndividual(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const individualLogin = createAsyncThunk(
  "client/individualLogin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await clientAPIs.individualLogin(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchClientById = createAsyncThunk(
  "client/fetchClientById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await clientAPIs.fetchClientById(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const makeBooking = createAsyncThunk(
  "client/makeBooking",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await clientAPIs.makeBooking(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchBookingsByClientId = createAsyncThunk(
  "client/fetchBookingsByClientId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await clientAPIs.fetchBookingsByClientId(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const cancelBookingById = createAsyncThunk(
  "client/cancelBookingById",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await clientAPIs.cancelBookingById(payload?.bookingId);
      dispatch(fetchBookingsByClientId(payload?.clientId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateGeneralInfo = createAsyncThunk(
  "client/updateGeneralInfo",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await clientAPIs.updateGeneralInfo(payload);
      dispatch(fetchClientById(payload?.clientId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateProfilePicture = createAsyncThunk(
  "client/updateProfilePicture",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await clientAPIs.updateProfilePicture(
        payload?.clientId,
        payload?.payload
      );
      dispatch(fetchClientById(payload?.clientId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
