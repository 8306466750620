import { Alert, Divider, Typography } from "antd";

const Blogs = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full my-10">
      <div className="flex flex-col items-center w-4/5">
        <Divider orientation="left">
          <Typography.Title level={3}>Blogs</Typography.Title>
        </Divider>
        <Alert
          message="
          The Artist Network will soon start publishing blogs on this link!"
        />
      </div>
    </div>
  );
};

export default Blogs;
