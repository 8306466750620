import { Card, Modal, Typography } from "antd";

const ContactUsModal = ({ open, closeModal }) => {
  return (
    <Modal
      open={open}
      title="Contact"
      footer={null}
      onCancel={() => closeModal()}
      centered
    >
      <div className="w-full p-4">
        <div className="w-full text-center">
          <Typography.Paragraph type="secondary">
            You can use the following information to reach out to us for booking
            this artist or regarding any other queries.
          </Typography.Paragraph>
        </div>
        <div className="flex justify-between w-full">
          <Typography.Paragraph>Phone Number -</Typography.Paragraph>
          <Typography.Paragraph>+917972231631</Typography.Paragraph>
        </div>
        <div className="flex justify-between w-full">
          <Typography.Paragraph>Email -</Typography.Paragraph>
          <Typography.Paragraph>media@theartistnetwork.in</Typography.Paragraph>
        </div>
      </div>
    </Modal>
  );
};

export default ContactUsModal;
