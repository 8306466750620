import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Form, Input, Modal, Select } from "antd";
import { selectCities } from "../../../reducers/address";
import { selectLoggedInArtist } from "../../../reducers/artist";
import { selectLoggedInClient } from "../../../reducers/client";
import {
  updateArtistAddress,
  updateClientAddress,
} from "../../../actions/address";
import { isStringNumeric } from "../../../utils/formValidator";

const { Option } = Select;

const EditAddressModal = ({ open, closeModal, address }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const cities = useSelector(selectCities);
  const artist = useSelector(selectLoggedInArtist);
  const client = useSelector(selectLoggedInClient);

  const handleSubmit = (values) => {
    if (!_.isEmpty(artist?.id)) {
      dispatch(
        updateArtistAddress({ ...values, id: address.id, artistId: artist?.id })
      );
    } else {
      dispatch(
        updateClientAddress({ ...values, id: address.id, clientId: client?.id })
      );
    }
    closeModal();
    form.resetFields();
  };

  const handleClose = () => {
    form.resetFields();
    closeModal();
  };

  if (_.isEmpty(cities)) {
    return (
      <Modal
        title="Edit Address"
        open={open}
        onOk={closeModal}
        onCancel={closeModal}
      >
        <Alert type="error" message="Error while fetching cities" />
      </Modal>
    );
  }

  return (
    <Modal
      open={open}
      title="Edit Address"
      okText="Edit"
      cancelText="Cancel"
      okButtonProps={{
        autoFocus: true,
        htmlType: "submit",
      }}
      onCancel={handleClose}
      modalRender={(dom) => (
        <Form
          layout="vertical"
          form={form}
          name="editAddressModal"
          initialValues={{
            addressName: address.addressName,
            houseNumber: address.houseNumber,
            streetName: address.streetName,
            pincode: address.pincode,
            cityId: address.cityId,
          }}
          requiredMark="optional"
          onFinish={handleSubmit}
        >
          {dom}
        </Form>
      )}
    >
      <Form.Item
        name="addressName"
        label="Address Type"
        rules={[
          {
            required: true,
            message: "Please input your Address Type!",
          },
        ]}
      >
        <Input placeholder="Home" />
      </Form.Item>

      <Form.Item
        name="houseNumber"
        label="House Number"
        rules={[
          {
            required: true,
            message: "Please input your House Number!",
          },
        ]}
      >
        <Input placeholder="Flat 203, Block A" />
      </Form.Item>

      <Form.Item
        name="streetName"
        label="Street Name"
        rules={[
          {
            required: true,
            message: "Please input your Street Name!",
          },
        ]}
      >
        <Input placeholder="Arodhya Layout, Gachibowli" />
      </Form.Item>

      <Form.Item
        name="pincode"
        label="Pincode"
        rules={[
          {
            required: true,
            message: "Please input your Pincode!",
          },
          {
            len: 6,
            message: "Pincode has to be of 6 numbers!",
          },
          () => ({
            validator: isStringNumeric("Pincode"),
          }),
        ]}
      >
        <Input placeholder="500XXX" />
      </Form.Item>

      <Form.Item
        name="cityId"
        label="City"
        rules={[{ required: true, message: "Please select your city!" }]}
      >
        <Select placeholder="Nagpur">
          {cities.map((city) => (
            <Option
              value={city.id}
            >{`${city.name}, ${city.state.name}`}</Option>
          ))}
        </Select>
      </Form.Item>
    </Modal>
  );
};

export default EditAddressModal;
