import { useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { deleteTermsAndConditions } from "../../../../../actions/artist";

const Condition = ({ condition }) => {
  const dispatch = useDispatch();
  const showDeleteConfirm = () => {
    Modal.confirm({
      title: "Are you sure delete this condition?",
      icon: <ExclamationCircleFilled />,
      content:
        "This will delete the condition permanently. You will have to add proper terms and conditions to showcase to the clients.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        dispatch(
          deleteTermsAndConditions({
            artistId: condition.artistId,
            termsAndConditionsId: condition.id,
          })
        );
      },
      onCancel() {},
    });
  };

  return (
    <div className="w-full p-6 rounded-md shadow">
      {condition.content}
      <div className="flex justify-end w-full pt-6">
        <Button onClick={showDeleteConfirm} icon={<DeleteOutlined />} danger />
      </div>
    </div>
  );
};

export default Condition;
