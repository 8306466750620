import { useNavigate } from "react-router-dom";
import { Divider, Tag, Typography } from "antd";
import { formatHumanReadableDateWithoutTime } from "../../utils/common";
import { ROUTES_MAP } from "../../constants/common";

const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center w-full my-10">
      <div className="flex justify-end w-4/5">
        <Tag>
          Last Updated on -{" "}
          {formatHumanReadableDateWithoutTime(new Date("11-06-2024"))}
        </Tag>
      </div>
      <div className="flex flex-col items-center w-4/5">
        <Divider orientation="left">
          <Typography.Title level={3}>Terms & Conditions</Typography.Title>
        </Divider>

        <div className="md:px-10">
          <Typography.Paragraph className="m-0 text-slate-400">
            Welcome to The Artist Network! By accessing or using our Website (
            <Typography.Link
              className="underline text-slate-400"
              onClick={() => navigate(ROUTES_MAP.HOME.path)}
            >
              https://www.theartistnetwork.net
            </Typography.Link>
            ), you agree to comply with and be bound by these Terms and
            Conditions. Please read them carefully.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Acceptance of Terms
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            By using our Website, you agree to these Terms and Conditions. If
            you do not agree with any part of them, please do not use the
            Website.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Services Provided
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            The Artist Network provides a platform for clients to book artists
            for events such as corporate functions, weddings, concerts, and
            other social gatherings. We facilitate the booking process between
            clients and artists, including managing advance payments and
            ensuring that artists receive payment upon completion of events.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            User Registration and Account Management
          </Typography.Title>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Eligibility -</strong> You must be at least 18 years old to
            use our services.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Account Creation -</strong> Users, including artists and
            clients, must create an account to access certain features.
            Registration requires accurate and complete information, including
            your name, phone number, email, profile picture, and other relevant
            details.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Artist Information -</strong> Artists may upload their
            profile information, pricing, embedded YouTube links, and other
            details to showcase their work. All profiles are manually verified
            by The Artist Network through direct contact with the artist.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Account Security -</strong> Users are responsible for
            maintaining the confidentiality of their account information. The
            Artist Network is not liable for any unauthorized account access due
            to your failure to secure your login credentials.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Bookings and Payments
          </Typography.Title>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Booking Process -</strong> Clients can book an artist
            through the Website. The Artist Network will confirm the booking
            with the artist and notify the client upon confirmation.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Advance Payment -</strong> Upon booking, clients are
            required to make an advance payment. The Artist Network will deduct
            a commission from this amount before passing the remaining balance
            to the artist.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Remaining Payment -</strong> Clients are responsible for
            paying the remaining amount directly to the artist upon completion
            of the event.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Service Fee -</strong> Our commission is calculated as a
            percentage of the advance payment and is non-refundable, except as
            outlined in our cancellation policy.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Cookies and User Sessions
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            The Artist Network uses browser cookies to maintain user sessions
            and keep users logged in while browsing. By using our Website, you
            consent to our use of cookies as outlined in our Privacy Policy.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            User Conduct
          </Typography.Title>
          <Typography.Paragraph className="m-0 text-slate-400">
            By using our Website, you agree to -
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            Provide accurate information.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            Refrain from posting inappropriate, offensive, or defamatory
            content.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            Not impersonate others or misrepresent yourself.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Intellectual Property
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            All content on this Website, including text, graphics, logos,
            images, and software, is owned by The Artist Network or its content
            providers and is protected by applicable copyright laws.
            Unauthorized use or reproduction is strictly prohibited.
          </Typography.Paragraph>
          <Typography.Title className="text-slate-500" level={5}>
            Limitation of Liability
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            The Artist Network is not responsible for any direct or indirect
            damages arising from the use or inability to use the Website or
            services, nor are we liable for the actions or performance of
            artists or clients.
          </Typography.Paragraph>
          <Typography.Title className="text-slate-500" level={5}>
            Indemnification
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            You agree to indemnify, defend, and hold harmless The Artist
            Network, its employees, agents, and affiliates from any claims,
            damages, or expenses arising from your use of the Website.
          </Typography.Paragraph>
          <Typography.Title className="text-slate-500" level={5}>
            Modifications to Terms
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            The Artist Network reserves the right to modify these Terms and
            Conditions at any time. Changes will be posted on this page, and
            your continued use of the Website signifies acceptance of the
            updated terms.
          </Typography.Paragraph>
          <Typography.Title className="text-slate-500" level={5}>
            Governing Law and Dispute Resolution
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            These Terms and Conditions are governed by the laws of India. Any
            disputes arising out of or relating to these terms shall be resolved
            in the courts of [Specify Jurisdiction, e.g., Mumbai].
          </Typography.Paragraph>
          <Typography.Title className="text-slate-500" level={5}>
            Contact Information
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            If you have any questions or concerns regarding these Terms and
            Conditions, please contact us at{" "}
            <Typography.Link
              className="underline text-slate-400"
              href="mailto:media@theartistnetwork.in"
            >
              media@theartistnetwork.in
            </Typography.Link>
            .
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
