import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCity } from "../../../reducers/artist";
import { fetchArtists } from "../../../actions/artist";
import SelectCity from "./City/SelectCity";
import Filter from "./Filter";
import Search from "./Search";

const Navbar = () => {
  const dispatch = useDispatch();
  const city = useSelector(selectCity);
  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] = useState({
    rating: null,
    type: [],
    price: {
      from: null,
      to: null,
    },
  });

  const onSearch = (value) => {
    setSearchInput(value);
    dispatch(fetchArtists({ name: value, filters, cityId: city?.id }));
  };

  const onFilter = (value) => {
    setFilters(value);
    dispatch(
      fetchArtists({ name: searchInput, filters: value, cityId: city?.id })
    );
  };

  return (
    <div className="flex flex-col items-center justify-center w-full gap-2 md:flex-row">
      <div className="hidden w-fit md:block">
        <SelectCity />
      </div>
      <div className="md:w-[50%] w-full">
        <Search onSearch={onSearch} />
      </div>
      <div className="hidden w-fit md:block">
        <Filter filters={filters} onFilter={onFilter} />
      </div>
      <div className="flex justify-center gap-2 md:hidden">
        <SelectCity />
        <Filter filters={filters} onFilter={onFilter} />
      </div>
    </div>
  );
};

export default Navbar;
