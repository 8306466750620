import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Divider,
  Result,
  Row,
  Spin,
  Typography,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  selectAddresses,
  selectCreateAddressLoading,
  selectFetchAddressesError,
  selectFetchAddressesLoading,
} from "../../reducers/address";
import {
  fetchAddressesByArtistId,
  fetchAddressesByClientId,
  fetchCities,
} from "../../actions/address";
import { selectLoggedInArtist } from "../../reducers/artist";
import { selectLoggedInClient } from "../../reducers/client";
import { ROUTES_MAP } from "../../constants/common";
import Address from "./Address";
import AddAddressModal from "./Modal/AddAddress";

const Addresses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const artistId = useSelector(selectLoggedInArtist)?.id;
  const clientId = useSelector(selectLoggedInClient)?.id;
  const fetchAddressesLoading = useSelector(selectFetchAddressesLoading);
  const addAddressLoading = useSelector(selectCreateAddressLoading);
  const error = useSelector(selectFetchAddressesError);
  const addresses = useSelector(selectAddresses);
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(artistId)) {
      dispatch(fetchAddressesByArtistId(artistId));
    }
    if (!_.isEmpty(clientId)) {
      dispatch(fetchAddressesByClientId(clientId));
    }
    dispatch(fetchCities());
  }, [artistId, clientId]);

  const handleAddAddressModalClose = () => {
    setIsAddAddressModalOpen(false);
  };

  if (fetchAddressesLoading || addAddressLoading) {
    return (
      <div className="flex justify-center items-center w-full h-[50vh]">
        <Spin size="large" />
      </div>
    );
  }

  if (!_.isEmpty(error)) {
    return (
      <div className="flex justify-center w-full my-10">
        <Alert message={error} type="error" />
      </div>
    );
  }

  if (_.isEmpty(artistId) && _.isEmpty(clientId)) {
    return (
      <div className="flex justify-center w-full my-10">
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button
              type="primary"
              onClick={() => navigate(ROUTES_MAP.HOME.path)}
            >
              Back Home
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <Fragment>
      <AddAddressModal
        open={isAddAddressModalOpen}
        closeModal={handleAddAddressModalClose}
      />
      <div className="flex flex-col items-center justify-center w-full my-10">
        <div className="w-4/5">
          <Divider orientation="left">
            <Typography.Title level={3}>Addresses</Typography.Title>
          </Divider>

          {!_.isEmpty(addresses) ? (
            <Row gutter={24} justify="space-around">
              {addresses.map((address, colIndex) => (
                <Col
                  className="flex justify-center mb-8"
                  xs={24} // Full width on extra small screens
                  sm={12} // Half width on small screens
                  md={12} // One-third width on medium screens
                  lg={8} // One-fourth width on large screens
                  key={colIndex}
                >
                  <Address address={address} />
                </Col>
              ))}
            </Row>
          ) : (
            <div className="flex justify-center w-full my-10">
              <Alert
                message="No Addresses added! Click below to add your address"
                type="info"
              />
            </div>
          )}

          {((_.isEmpty(addresses) && !_.isEmpty(artistId)) ||
            !_.isEmpty(clientId)) && (
            <div className="flex justify-center w-full">
              <Button
                onClick={() => setIsAddAddressModalOpen(true)}
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Addresses;
