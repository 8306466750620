import _ from "lodash";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Row, Col, Pagination, Spin } from "antd";
import { fetchArtists } from "../../actions/artist";
import {
  selectArtists,
  selectCity,
  selectArtistsLoading,
  selectArtistsPagination,
} from "../../reducers/artist";
import commonConstants from "../../constants/common";
import Artist from "./Artist";

const Artists = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectArtistsLoading);
  const artists = useSelector(selectArtists) || [];
  const city = useSelector(selectCity);
  const pagination = useSelector(selectArtistsPagination);
  const [current, setCurrent] = useState(pagination?.currentPage);

  useEffect(() => {
    dispatch(fetchArtists({ cityId: city?.id }));
  }, []);

  const onPageChange = (page) => {
    dispatch(fetchArtists({ cityId: city?.id, page }));
    setCurrent(page);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-[50vh]">
        <Spin size="large" />
      </div>
    );
  }

  if (_.isEmpty(artists)) {
    return (
      <div className="flex justify-center w-full">
        <Alert message="No Artists Found." type="error" />
      </div>
    );
  }

  return (
    <div className="w-full">
      <Row gutter={[24, 24]} justify="space-around">
        {artists.map((artist, colIndex) => (
          <Col
            className="flex justify-center mb-8"
            xs={24} // Full width on extra small screens
            sm={12} // Half width on small screens
            md={8} // One-third width on medium screens
            lg={6} // One-fourth width on large screens
            key={colIndex}
          >
            <Artist artist={artist} />
          </Col>
        ))}
      </Row>
      {pagination?.totalPages > 1 && (
        <div className="flex justify-center w-full">
          <Pagination
            current={current}
            onChange={onPageChange}
            pageSize={commonConstants.DEFAULT_PAGE_SIZES.ARTISTS}
            total={pagination?.totalArtists || 5}
          />
        </div>
      )}
    </div>
  );
};

export default Artists;
