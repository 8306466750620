import Icon from "@ant-design/icons";
import customIcon from "../../../images/icons/money.png";

const CustomIcon = () => (
  <img
    src={customIcon}
    alt="Price Icon"
    style={{ width: "35px" }} // You can adjust the size here
  />
);

const PriceIcon = () => <Icon component={CustomIcon} />;

export default PriceIcon;
