import _ from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Spin, Result } from "antd";
import {
  selectEditProfileLoading,
  selectLoggedInClient,
} from "../../../../reducers/client";
import { ROUTES_MAP } from "../../../../constants/common";
import General from "./General";
import ProfilePicture from "./ProfilePicture";

const EditProfile = () => {
  const navigate = useNavigate();
  const client = useSelector(selectLoggedInClient);
  const loading = useSelector(selectEditProfileLoading);
  const clientId = client?.id;

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-[50vh]">
        <Spin size="large" />
      </div>
    );
  }

  if (_.isEmpty(clientId)) {
    return (
      <div className="flex justify-center w-full my-10">
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button
              type="primary"
              onClick={() => navigate(ROUTES_MAP.HOME.path)}
            >
              Back Home
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center w-full my-10">
      <div className="w-4/5">
        <ProfilePicture client={client} />
        <General client={client} />
      </div>
    </div>
  );
};

export default EditProfile;
