import Icon from "@ant-design/icons";
import customIcon from "../../../images/icons/gender.png";

const CustomIcon = () => (
  <img
    src={customIcon}
    alt="Gender Icon"
    style={{ width: "35px" }} // You can adjust the size here
  />
);

const GenderIcon = () => <Icon component={CustomIcon} />;

export default GenderIcon;
