import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Modal, Select } from "antd";
import { selectLoggedInClient } from "../../../../reducers/client";
import { isStringNumeric } from "../../../../utils/formValidator";
import { updateGeneralInfo } from "../../../../actions/client";

const { Option } = Select;

const UpdateGeneralInfoModal = ({ open, closeModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const client = useSelector(selectLoggedInClient);

  const handleSubmit = (values) => {
    dispatch(
      updateGeneralInfo({
        ...values,
        clientId: client.id,
      })
    );
    form.resetFields();
    closeModal();
  };

  const handleClose = () => {
    form.resetFields();
    closeModal();
  };

  return (
    <Modal
      open={open}
      title="General"
      okText="Update"
      cancelText="Cancel"
      okButtonProps={{
        autoFocus: true,
        htmlType: "submit",
      }}
      onCancel={handleClose}
      destroyOnClose
      modalRender={(dom) => (
        <Form
          layout="vertical"
          form={form}
          name="updateGeneralInfoModal"
          requiredMark="optional"
          initialValues={{
            name: client.name,
            email: client.email,
            contact: client.contact,
            typeId: client.typeId,
          }}
          onFinish={handleSubmit}
        >
          {dom}
        </Form>
      )}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: "Please input your Name!",
          },
          {
            max: 30,
            message: "Name cannot be longer than 30 letters!",
          },
        ]}
      >
        <Input placeholder="XYZ Restaurant" />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input placeholder="name@example.com" disabled />
      </Form.Item>

      <Form.Item
        name="contact"
        label="Phone Number"
        rules={[
          { required: true, message: "Please input your phone number!" },
          {
            len: 10,
            message: "Phone Number should be of length 10!",
          },
          () => ({
            validator: isStringNumeric("Phone Number"),
          }),
        ]}
      >
        <Input
          addonBefore={
            <Form.Item name="prefix" noStyle>
              <Select defaultValue="91" style={{ width: 70 }}>
                <Option value="91">+91</Option>
              </Select>
            </Form.Item>
          }
          placeholder="90324XXXXX"
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        name="typeId"
        label="Type"
        rules={[
          {
            required: true,
            message: "Please select your Type!",
          },
        ]}
      >
        <Select placeholder="Restaurant" disabled>
          <Option value={1}>Restaurant</Option>
        </Select>
      </Form.Item>
    </Modal>
  );
};

export default UpdateGeneralInfoModal;
