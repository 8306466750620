import { createSlice } from "@reduxjs/toolkit";
import * as addressActions from "../actions/address";
import messageConstants from "../constants/message";

const initialErrorState = {
  cities: null,
  fetch: null,
  update: null,
  delete: null,
  add: null,
};

const initialLoadingState = {
  cities: false,
  fetch: false,
  update: false,
  delete: false,
  add: false,
};

const initialSuccessState = {
  add: null,
  delete: null,
};

export const addressSlice = createSlice({
  name: "address",
  initialState: {
    addresses: [],
    cities: [],
    error: initialErrorState,
    loading: initialLoadingState,
    success: initialSuccessState,
  },
  reducers: {
    clearCreateAddressSuccess: (state) => {
      state.success.add = null;
    },
    clearUpdateAddressSuccess: (state) => {
      state.success.update = null;
    },
    clearDeleteAddressSuccess: (state) => {
      state.success.delete = null;
    },
    clearCreateAddressError: (state) => {
      state.error.add = null;
    },
    clearUpdateAddressError: (state) => {
      state.error.update = null;
    },
    clearDeleteAddressError: (state) => {
      state.error.delete = null;
    },
  },
  extraReducers(builder) {
    builder
      // Creating Artist addresses
      .addCase(addressActions.createArtistAddress.pending, (state) => {
        state.loading.add = true;
      })
      .addCase(addressActions.createArtistAddress.fulfilled, (state) => {
        state.loading.add = false;
        state.success.add = messageConstants.DEFAULT_SUCCESS.CREATE_ADDRESS;
      })
      .addCase(addressActions.createArtistAddress.rejected, (state, action) => {
        state.loading.add = false;
        state.error.add =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.CREATE_ADDRESS;
      })

      // Updating Artist Addresses
      .addCase(addressActions.updateArtistAddress.pending, (state) => {
        state.loading.update = true;
      })
      .addCase(addressActions.updateArtistAddress.fulfilled, (state) => {
        state.loading.update = false;
        state.success.update = messageConstants.DEFAULT_SUCCESS.UPDATE_ADDRESS;
      })
      .addCase(addressActions.updateArtistAddress.rejected, (state, action) => {
        state.loading.update = false;
        state.error.update =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.UPDATE_ADDRESS;
      })

      // Deleting Artist Addresses
      .addCase(addressActions.deleteArtistAddress.pending, (state) => {
        state.loading.delete = true;
      })
      .addCase(addressActions.deleteArtistAddress.fulfilled, (state) => {
        state.loading.delete = false;
        state.success.delete = messageConstants.DEFAULT_SUCCESS.DELETE_ADDRESS;
      })
      .addCase(addressActions.deleteArtistAddress.rejected, (state, action) => {
        state.loading.delete = false;
        state.error.delete =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.DELETE_ADDRESS;
      })

      // Fetching Addresses of Artist
      .addCase(addressActions.fetchAddressesByArtistId.pending, (state) => {
        state.loading.fetch = true;
      })
      .addCase(
        addressActions.fetchAddressesByArtistId.fulfilled,
        (state, action) => {
          state.loading.fetch = false;
          state.addresses = action.payload?.addresses;
        }
      )
      .addCase(
        addressActions.fetchAddressesByArtistId.rejected,
        (state, action) => {
          state.loading.fetch = false;
          state.error.fetch =
            action?.payload?.message ||
            messageConstants.DEFAULT_ERROR.FETCH_ADDRESSES_BY_ARTIST_ID;
        }
      )

      // Creating Client addresses
      .addCase(addressActions.createClientAddress.pending, (state) => {
        state.loading.add = true;
      })
      .addCase(addressActions.createClientAddress.fulfilled, (state) => {
        state.loading.add = false;
        state.success.add = messageConstants.DEFAULT_SUCCESS.CREATE_ADDRESS;
      })
      .addCase(addressActions.createClientAddress.rejected, (state, action) => {
        state.loading.add = false;
        state.error.add =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.CREATE_ADDRESS;
      })

      // Updating Client Addresses
      .addCase(addressActions.updateClientAddress.pending, (state) => {
        state.loading.update = true;
      })
      .addCase(addressActions.updateClientAddress.fulfilled, (state) => {
        state.loading.update = false;
        state.success.update = messageConstants.DEFAULT_SUCCESS.UPDATE_ADDRESS;
      })
      .addCase(addressActions.updateClientAddress.rejected, (state, action) => {
        state.loading.update = false;
        state.error.update =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.UPDATE_ADDRESS;
      })

      // Deleting Client Addresses
      .addCase(addressActions.deleteClientAddress.pending, (state) => {
        state.loading.delete = true;
      })
      .addCase(addressActions.deleteClientAddress.fulfilled, (state) => {
        state.loading.delete = false;
        state.success.delete = messageConstants.DEFAULT_SUCCESS.DELETE_ADDRESS;
      })
      .addCase(addressActions.deleteClientAddress.rejected, (state, action) => {
        state.loading.delete = false;
        state.error.delete =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.DELETE_ADDRESS;
      })

      // Fetching Addresses of Client
      .addCase(addressActions.fetchAddressesByClientId.pending, (state) => {
        state.loading.fetch = true;
      })
      .addCase(
        addressActions.fetchAddressesByClientId.fulfilled,
        (state, action) => {
          state.loading.fetch = false;
          state.addresses = action.payload?.addresses;
        }
      )
      .addCase(
        addressActions.fetchAddressesByClientId.rejected,
        (state, action) => {
          state.loading.fetch = false;
          state.error.fetch =
            action?.payload?.message ||
            messageConstants.DEFAULT_ERROR.FETCH_ADDRESSES_BY_ARTIST_ID;
        }
      )

      // Fetching cities
      .addCase(addressActions.fetchCities.pending, (state) => {
        state.loading.cities = true;
      })
      .addCase(addressActions.fetchCities.fulfilled, (state, action) => {
        state.loading.cities = false;
        state.cities = action.payload?.cities;
      })
      .addCase(addressActions.fetchCities.rejected, (state, action) => {
        state.loading.cities = false;
      });
  },
});

export const selectAddresses = (state) => state.address.addresses;
export const selectCities = (state) => state.address.cities;

// Error messages
export const selectFetchCitiesError = (state) => state.address.error.cities;
export const selectFetchAddressesError = (state) => state.address.error.fetch;
export const selectCreateAddressError = (state) => state.address.error.add;
export const selectUpdateAddressError = (state) => state.address.error.update;
export const selectDeleteAddressError = (state) => state.address.error.delete;

// Loading states
export const selectFetchCitiesLoading = (state) => state.address.loading.cities;
export const selectFetchAddressesLoading = (state) =>
  state.address.loading.fetch;
export const selectCreateAddressLoading = (state) => state.address.loading.add;
export const selectUpdateAddressLoading = (state) =>
  state.address.loading.update;
export const selectDeleteAddressLoading = (state) =>
  state.address.loading.delete;

// Success messages
export const selectCreateAddressSuccess = (state) => state.address.success.add;
export const selectUpdateAddressSuccess = (state) =>
  state.address.success.update;
export const selectDeleteAddressSuccess = (state) =>
  state.address.success.delete;

export const {
  clearCreateAddressSuccess,
  clearUpdateAddressSuccess,
  clearDeleteAddressSuccess,
  clearCreateAddressError,
  clearUpdateAddressError,
  clearDeleteAddressError,
} = addressSlice.actions;

export default addressSlice.reducer;
