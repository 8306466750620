import { useNavigate } from "react-router-dom";
import { Button, Modal, Result } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { ROUTES_MAP } from "../../../../constants/common";

const BookingSuccessModal = ({ open, closeModal }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      title={null}
      footer={null}
      onCancel={() => closeModal()}
      centered
    >
      <Result
        status="success"
        title="Booking Request Successful!"
        subTitle={
          <div>
            <div>Your booking request has been sent to the artist.</div>
            <div>
              We will work with the artist to provide you with a confirmation
              within <strong>3 hours</strong>!
            </div>
          </div>
        }
        icon={<CheckCircleTwoTone twoToneColor="#52c41a" />} // Use Ant Design's TwoTone icon
        extra={[
          <Button
            key="bookings"
            onClick={() => navigate(ROUTES_MAP.CLIENT_BOOKINGS.path)}
          >
            Go to My Bookings
          </Button>,
        ]}
      />
    </Modal>
  );
};

export default BookingSuccessModal;
