import { Divider, Tag, Typography } from "antd";
import { formatHumanReadableDateWithoutTime } from "../../utils/common";

const RefundAndCancellation = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full my-10">
      <div className="flex justify-end w-4/5">
        <Tag>
          Last Updated on -{" "}
          {formatHumanReadableDateWithoutTime(new Date("11-06-2024"))}
        </Tag>
      </div>
      <div className="flex flex-col items-center w-4/5">
        <Divider orientation="left">
          <Typography.Title level={3}>Refund Policy</Typography.Title>
        </Divider>

        <div className="md:px-10">
          <Typography.Title className="text-slate-500" level={5}>
            General Policy
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            Advance payments made by clients upon booking are subject to a
            non-refundable commission fee. Refunds on the remaining balance
            depend on the timing of the cancellation, as outlined below.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Refund Eligibility
          </Typography.Title>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Artist-Initiated Cancellations -</strong> If an artist
            cancels a confirmed booking, the client will receive a full refund
            of the advance payment, including our commission.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Client-Initiated Cancellations -</strong>
          </Typography.Paragraph>
          <Typography.Paragraph className="pl-6 m-0 text-slate-400">
            <strong>Cancellations within 2 Weeks of the Event -</strong> No
            refund will be issued if the client cancels within 2 weeks of the
            scheduled event date.
          </Typography.Paragraph>
          <Typography.Paragraph className="pl-6 m-0 text-slate-400">
            <strong>Cancellations within 3 to 4 Weeks of the Event -</strong> If
            the client cancels between 3 and 4 weeks before the event date, they
            will be eligible for a 50% refund of the advance payment, after
            deducting our commission.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Refund Processing
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            Refunds are processed within 3 business days of the cancellation
            request. Refunds are issued back to the original payment method.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Non-Refundable Cases
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            No refunds will be issued for cancellations made within 2 weeks of
            the scheduled event. For more details on specific refund
            eligibility, please contact our support team at{" "}
            <Typography.Link
              className="underline text-slate-400"
              href="mailto:media@theartistnetwork.in"
            >
              media@theartistnetwork.in
            </Typography.Link>
            .
          </Typography.Paragraph>
        </div>
      </div>

      <div className="flex flex-col items-center w-4/5 mt-10">
        <Divider orientation="left">
          <Typography.Title level={3}>Cancellation Policy</Typography.Title>
        </Divider>

        <div className="md:px-10">
          <Typography.Title className="text-slate-500" level={5}>
            Artist Cancellations
          </Typography.Title>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Cancellation Procedure -</strong> Artists who need to cancel
            a confirmed booking must contact The Artist Network immediately. We
            will evaluate the reason for cancellation, and if deemed valid, the
            client will receive a full refund.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Consequences -</strong> Artists who cancel without a valid
            reason may face account penalties, including suspension or removal
            from the platform.
          </Typography.Paragraph>
          <Typography.Title className="text-slate-500" level={5}>
            Client Cancellations
          </Typography.Title>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Cancellations within 2 Weeks of the Event -</strong> No
            refund will be provided if the client cancels within 2 weeks of the
            scheduled event.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Cancellations within 3 to 4 Weeks of the Event -</strong> If
            a client cancels between 3 and 4 weeks prior to the event, a 50%
            refund of the advance payment will be issued.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Rescheduling
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            Clients and artists may reschedule an event if both parties consent
            and the new date does not conflict with other bookings.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Changes to Cancellation Policy
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            The Artist Network reserves the right to modify this Cancellation
            Policy at any time. Changes will be posted on this page, and your
            continued use of the Website signifies acceptance of the updated
            terms.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Contact Us
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            For questions or to initiate a cancellation, please contact us at{" "}
            <Typography.Link
              className="underline text-slate-400"
              href="mailto:media@theartistnetwork.in"
            >
              media@theartistnetwork.in
            </Typography.Link>
            .
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  );
};

export default RefundAndCancellation;
