import _ from "lodash";
import { Fragment, useState } from "react";
import { Button, Card, Col, Divider, Row, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import {
  getAwardsString,
  getEventTypesString,
  getGenresString,
  getInstrumentsString,
  getLanguagesString,
} from "../../../../../utils/common";
import MusicIcon from "../../../../Custom/Icon/MusicIcon";
import AboutItem from "../../../../Custom/AboutItem";
import LanguageIcon from "../../../../Custom/Icon/LanguageIcon";
import EventIcon from "../../../../Custom/Icon/EventIcon";
import AwardIcon from "../../../../Custom/Icon/AwardIcon";
import InstrumentIcon from "../../../../Custom/Icon/InstrumentIcon";
import UpdateAboutInfoModal from "../../Modal/UpdateAboutInfo";

const About = ({
  artistGenres,
  artistInstruments,
  artistLanguages,
  artistEventTypes,
  artistAwards,
}) => {
  const [isUpdateInfoModalOpen, setIsUpdateInfoModalOpen] = useState(false);

  const closeUpdateInfoModalOpen = () => {
    setIsUpdateInfoModalOpen(false);
  };

  return (
    <Fragment>
      <UpdateAboutInfoModal
        open={isUpdateInfoModalOpen}
        closeModal={closeUpdateInfoModalOpen}
        artistGenres={artistGenres}
        artistAwards={artistAwards}
        artistEventTypes={artistEventTypes}
        artistInstruments={artistInstruments}
        artistLanguages={artistLanguages}
      />
      <Divider orientation="left">
        <Typography.Title level={5}>About</Typography.Title>
      </Divider>

      {(!_.isEmpty(artistGenres) ||
        !_.isEmpty(artistEventTypes) ||
        !_.isEmpty(artistAwards) ||
        !_.isEmpty(artistInstruments) ||
        !_.isEmpty(artistLanguages)) && (
        <Card
          className="w-full transition-shadow duration-300 shadow-sm"
          bordered
        >
          {!_.isEmpty(artistGenres) && (
            <Card.Grid className="w-full text-left md:w-1/2" hoverable={false}>
              <AboutItem
                icon={<MusicIcon />}
                title="Genre"
                content={getGenresString(artistGenres)}
              />
            </Card.Grid>
          )}
          {!_.isEmpty(artistEventTypes) && (
            <Card.Grid className="w-full text-left md:w-1/2" hoverable={false}>
              <AboutItem
                icon={<EventIcon />}
                title="Event"
                content={getEventTypesString(artistEventTypes)}
              />
            </Card.Grid>
          )}
          {!_.isEmpty(artistAwards) && (
            <Card.Grid className="w-full text-left md:w-1/2" hoverable={false}>
              <AboutItem
                icon={<AwardIcon />}
                title="Awards"
                content={getAwardsString(artistAwards)}
              />
            </Card.Grid>
          )}
          {!_.isEmpty(artistInstruments) && (
            <Card.Grid className="w-full text-left md:w-1/2" hoverable={false}>
              <AboutItem
                icon={<InstrumentIcon />}
                title="Instruments"
                content={getInstrumentsString(artistInstruments)}
              />
            </Card.Grid>
          )}
          {!_.isEmpty(artistLanguages) && (
            <Card.Grid className="w-full text-left md:w-1/2" hoverable={false}>
              <AboutItem
                icon={<LanguageIcon />}
                title="Languages"
                content={getLanguagesString(artistLanguages)}
              />
            </Card.Grid>
          )}
        </Card>
      )}

      <div className="flex justify-center w-full pt-8">
        <Button
          onClick={() => setIsUpdateInfoModalOpen(true)}
          icon={<EditOutlined />}
        >
          Update
        </Button>
      </div>
    </Fragment>
  );
};

export default About;
