import { Card, Typography } from "antd";

const ContactCard = () => {
  return (
    <Card className="w-full md:w-1/2">
      <div className="w-full mb-10 text-center">
        <Typography.Paragraph type="secondary">
          You can use the following information to reach out to us regarding
          bookings, how we work and what we do.
        </Typography.Paragraph>
      </div>
      <div className="flex justify-between w-full">
        <Typography.Paragraph>Phone Number -</Typography.Paragraph>
        <Typography.Paragraph>+917972231631</Typography.Paragraph>
      </div>
      <div className="flex justify-between w-full">
        <Typography.Paragraph>Email -</Typography.Paragraph>
        <Typography.Paragraph>media@theartistnetwork.in</Typography.Paragraph>
      </div>
    </Card>
  );
};

export default ContactCard;
