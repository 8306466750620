import axios from "axios";
import routeConstants from "../constants/route";

// Enable sending cookies with requests
axios.defaults.withCredentials = true;

const API = axios.create({
  baseURL: routeConstants.BASE_URL,
  withCredentials: true,
});

export const createArtist = (payload) =>
  API.post(`/${routeConstants.ROUTE.ARTIST}/sign-up`, payload);

export const artistLogin = (payload) =>
  API.post(`/${routeConstants.ROUTE.ARTIST}/login`, payload);

export const fetchArtists = (payload) =>
  API.post(`/${routeConstants.ROUTE.ARTIST}`, payload);

export const fetchArtistById = (id) =>
  API.get(`/${routeConstants.ROUTE.ARTIST}/${id}`);

export const addArtistMedia = (payload) =>
  API.post(
    `/${routeConstants.ROUTE.ARTIST}/${routeConstants.ROUTE.MEDIA}`,
    payload
  );

export const deleteArtistMedia = (id) =>
  API.delete(
    `/${routeConstants.ROUTE.ARTIST}/${routeConstants.ROUTE.MEDIA}/${id}`
  );

export const addTermsAndConditions = (payload) =>
  API.post(
    `/${routeConstants.ROUTE.ARTIST}/${routeConstants.ROUTE.TERMS_AND_CONDITIONS}`,
    payload
  );

export const deleteTermsAndConditions = (id) =>
  API.delete(
    `/${routeConstants.ROUTE.ARTIST}/${routeConstants.ROUTE.TERMS_AND_CONDITIONS}/${id}`
  );

export const updateAboutInfo = (payload) =>
  API.put(`/${routeConstants.ROUTE.ARTIST}/about`, payload);

export const updateProfilePicture = (id, payload) =>
  API.put(`/${routeConstants.ROUTE.ARTIST}/profile-picture/${id}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateGeneralInfo = (payload) =>
  API.put(`/${routeConstants.ROUTE.ARTIST}/general`, payload);

export const updateWorkInfo = (payload) =>
  API.put(`/${routeConstants.ROUTE.ARTIST}/work`, payload);

export const fetchMetadata = () => API.get("/metadata");

export const fetchBookingsByArtistId = (id) =>
  API.get(`/${routeConstants.ROUTE.ARTIST}/bookings/${id}`);

export const cancelBookingById = (id) =>
  API.post(`/${routeConstants.ROUTE.ARTIST}/booking/cancel/${id}`);

export const confirmBookingById = (id) =>
  API.post(`/${routeConstants.ROUTE.ARTIST}/booking/confirm/${id}`);

export const fetchAvailabilityByArtistId = (id) =>
  API.get(`/${routeConstants.ROUTE.ARTIST}/availability/${id}`);
