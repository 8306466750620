import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SmileOutlined } from "@ant-design/icons";
import { Button, Typography, Spin, Result } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  selectArtistLoginLoading,
  selectLoggedInArtist,
} from "../../../reducers/artist";
import LoginForm from "./LoginForm";
import { ROUTES_MAP } from "../../../constants/common";
import calendar from "../../../images/icons/calendar.png";

const ArtistLogin = () => {
  const navigate = useNavigate();
  const loading = useSelector(selectArtistLoginLoading);
  const loggedInArtist = useSelector(selectLoggedInArtist);

  if (loggedInArtist) {
    return (
      <Result
        icon={<SmileOutlined />}
        title="You are already logged in!"
        extra={
          <Button type="primary" onClick={() => navigate(ROUTES_MAP.HOME.path)}>
            Back Home
          </Button>
        }
      />
    );
  }

  return (
    <div className="w-full min-h-[90vh] pt-4 flex flex-auto md:flex-row flex-col-reverse justify-center items-center gap-6">
      <div className="flex items-center justify-around w-full md:w-1/2">
        <div className="w-2/3">
          {loading ? (
            <Spin indicator={<LoadingOutlined spin />} size="large" />
          ) : (
            <LoginForm />
          )}
        </div>
      </div>
      <div className="w-full md:w-1/3">
        <div className="flex flex-col gap-4 px-8 md:px-0">
          <div className="flex justify-center w-full">
            <img
              className="w-[100px] h-[100px]"
              src={calendar}
              alt="Calendar"
            />
          </div>
          <Typography.Title className="font-extrabold font-[Montserrat] m-0 text-center">
            Get your first gig!
          </Typography.Title>
          <Typography.Paragraph className="m-0 text-center text-slate-400">
            Connect with talented artists all over India to bring your vision to
            life. Explore portfolios, and hire the perfect artist for your
            project. Start your creative journey today!
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  );
};

export default ArtistLogin;
