import _ from "lodash";
import { Fragment, useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal, Rate, Space, Typography } from "antd";
import commonConstants from "../../../constants/common";
import { capitalizeFirstLetter } from "../../../utils/common";

const Filter = ({ filters, onFilter }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedFilters, setUpdatedFilters] = useState(filters);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    onFilter(updatedFilters);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setUpdatedFilters(filters);
  };

  const onRatingsChange = (value) => {
    setUpdatedFilters((prevState) => ({
      ...prevState,
      rating: value,
    }));
  };

  const onTypeAdd = (value) => {
    setUpdatedFilters((prevState) => ({
      ...prevState,
      type: [...prevState.type, value],
    }));
  };

  const onTypeRemove = (value) => {
    setUpdatedFilters((prevState) => ({
      ...prevState,
      type: prevState.type.filter((type) => type !== value),
    }));
  };

  const onPriceChange = (from, to) => {
    setUpdatedFilters((prevState) => ({
      ...prevState,
      price: {
        from,
        to,
      },
    }));
  };

  return (
    <Fragment>
      <Modal
        title="Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: _.isEqual(filters, updatedFilters),
        }}
      >
        <div className="flex my-6">
          <div className="flex justify-start w-1/2">
            <Space direction="vertical">
              <Typography.Paragraph className="font-bold underline">
                Rating
              </Typography.Paragraph>
              <Space direction="vertical">
                <Checkbox
                  checked={updatedFilters?.rating === 4}
                  onChange={(event) =>
                    onRatingsChange(event.target.checked ? 4 : null)
                  }
                >
                  <div className="flex items-center gap-2">
                    <Rate disabled value={4} />
                    <div className="flex items-center h-full">& Up</div>
                  </div>
                </Checkbox>
                <Checkbox
                  checked={updatedFilters?.rating === 3}
                  onChange={(event) =>
                    onRatingsChange(event.target.checked ? 3 : null)
                  }
                >
                  <div className="flex items-center gap-2">
                    <Rate disabled value={3} />
                    <div className="flex items-center h-full">& Up</div>
                  </div>
                </Checkbox>
                <Checkbox
                  checked={updatedFilters?.rating === 2}
                  onChange={(event) =>
                    onRatingsChange(event.target.checked ? 2 : null)
                  }
                >
                  <div className="flex items-center gap-2">
                    <Rate disabled value={2} />
                    <div className="flex items-center h-full">& Up</div>
                  </div>
                </Checkbox>
              </Space>
            </Space>
          </div>

          <div className="flex justify-center w-1/2">
            <Space direction="vertical">
              <Typography.Paragraph className="font-bold underline">
                Type
              </Typography.Paragraph>
              <Space direction="vertical">
                <Checkbox
                  checked={updatedFilters.type.includes(
                    commonConstants.ARTIST_TYPES.DRUMMER
                  )}
                  onChange={(event) => {
                    const callBack = event.target.checked
                      ? onTypeAdd
                      : onTypeRemove;
                    callBack(commonConstants.ARTIST_TYPES.DRUMMER);
                  }}
                >
                  {capitalizeFirstLetter(commonConstants.ARTIST_TYPES.DRUMMER)}
                </Checkbox>
                <Checkbox
                  checked={updatedFilters.type.includes(
                    commonConstants.ARTIST_TYPES.SINGER
                  )}
                  onChange={(event) => {
                    const callBack = event.target.checked
                      ? onTypeAdd
                      : onTypeRemove;
                    callBack(commonConstants.ARTIST_TYPES.SINGER);
                  }}
                >
                  {capitalizeFirstLetter(commonConstants.ARTIST_TYPES.SINGER)}
                </Checkbox>
                <Checkbox
                  checked={updatedFilters.type.includes(
                    commonConstants.ARTIST_TYPES.GUITARIST
                  )}
                  onChange={(event) => {
                    const callBack = event.target.checked
                      ? onTypeAdd
                      : onTypeRemove;
                    callBack(commonConstants.ARTIST_TYPES.GUITARIST);
                  }}
                >
                  {capitalizeFirstLetter(
                    commonConstants.ARTIST_TYPES.GUITARIST
                  )}
                </Checkbox>
                <Checkbox
                  checked={updatedFilters.type.includes(
                    commonConstants.ARTIST_TYPES.DANCER
                  )}
                  onChange={(event) => {
                    const callBack = event.target.checked
                      ? onTypeAdd
                      : onTypeRemove;
                    callBack(commonConstants.ARTIST_TYPES.DANCER);
                  }}
                >
                  {capitalizeFirstLetter(commonConstants.ARTIST_TYPES.DANCER)}
                </Checkbox>
              </Space>
            </Space>
          </div>
        </div>

        <div className="flex my-6">
          <div className="flex justify-start w-1/2">
            <Space direction="vertical">
              <Typography.Paragraph className="font-bold underline">
                Price
              </Typography.Paragraph>
              <Space direction="vertical">
                <Checkbox
                  checked={
                    updatedFilters?.price?.from === 10000 &&
                    !updatedFilters?.price?.to
                  }
                  onChange={(event) => {
                    const from = event.target.checked ? 10000 : null;
                    const to = null;
                    onPriceChange(from, to);
                  }}
                >
                  &#8377; 10,000 & Up
                </Checkbox>
                <Checkbox
                  checked={
                    updatedFilters?.price?.from === 5000 &&
                    updatedFilters?.price?.to === 10000
                  }
                  onChange={(event) => {
                    const from = event.target.checked ? 5000 : null;
                    const to = event.target.checked ? 10000 : null;
                    onPriceChange(from, to);
                  }}
                >
                  &#8377; 5,000 to &#8377; 10,000
                </Checkbox>
                <Checkbox
                  checked={
                    updatedFilters?.price?.from === 2500 &&
                    updatedFilters?.price?.to === 5000
                  }
                  onChange={(event) => {
                    const from = event.target.checked ? 2500 : null;
                    const to = event.target.checked ? 5000 : null;
                    onPriceChange(from, to);
                  }}
                >
                  &#8377; 2,500 to &#8377; 5,000
                </Checkbox>
                <Checkbox
                  checked={
                    updatedFilters?.price?.from === 0 &&
                    updatedFilters?.price?.to === 2500
                  }
                  onChange={(event) => {
                    const from = event.target.checked ? 0 : null;
                    const to = event.target.checked ? 2500 : null;
                    onPriceChange(from, to);
                  }}
                >
                  &#8377; 0 to &#8377; 2,500
                </Checkbox>
              </Space>
            </Space>
          </div>
        </div>
      </Modal>
      <Button size="large" icon={<FilterOutlined />} onClick={showModal}>
        Filter
      </Button>
    </Fragment>
  );
};

export default Filter;
