import { useNavigate } from "react-router-dom";
import { Divider, Tag, Typography } from "antd";
import { formatHumanReadableDateWithoutTime } from "../../utils/common";
import { ROUTES_MAP } from "../../constants/common";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center w-full my-10">
      <div className="flex justify-end w-4/5">
        <Tag>
          Last Updated on -{" "}
          {formatHumanReadableDateWithoutTime(new Date("11-06-2024"))}
        </Tag>
      </div>
      <div className="flex flex-col items-center w-4/5">
        <Divider orientation="left">
          <Typography.Title level={3}>Privacy Policy</Typography.Title>
        </Divider>

        <div className="md:px-10">
          <Typography.Paragraph className="m-0 text-slate-400">
            The Artist Network ("we," "our," or "us") values your privacy. This
            Privacy Policy outlines how we collect, use, store, and share your
            information when you use our website,{" "}
            <Typography.Link
              className="underline text-slate-400"
              onClick={() => navigate(ROUTES_MAP.HOME.path)}
            >
              https://www.theartistnetwork.net
            </Typography.Link>{" "}
            ("Website").
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Information We Collect
          </Typography.Title>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Personal Information -</strong> We collect information when
            you create an account, including your name, phone number, email,
            profile picture, and, for artists, embedded YouTube links, pricing,
            and profile information.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Usage Data -</strong> We collect data on how you interact
            with the Website, including IP address, browser type, device
            information, and pages visited.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Cookies -</strong> We use cookies to manage user sessions
            and keep you logged in. You can control cookie settings through your
            browser.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            How We Use Your Information
          </Typography.Title>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>To Provide Services -</strong> We use your information to
            manage your account, facilitate artist bookings, and process
            payments.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Communication -</strong> We may use your email or phone
            number to send booking confirmations, updates, or important notices.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Improving Services -</strong> We analyze usage data to
            improve our Website functionality and user experience.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Marketing -</strong> With your consent, we may send
            promotional offers, newsletters, or updates.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Information Sharing and Disclosure
          </Typography.Title>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>With Artists and Clients -</strong> For booking purposes,
            client details are shared with the booked artist, and artist details
            are shared with the client.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Third-Party Services -</strong> We may share information
            with service providers who help us with payment processing, email
            communications, and analytics.
          </Typography.Paragraph>
          <Typography.Paragraph className="m-0 text-slate-400">
            <strong>Legal Compliance -</strong> We may disclose information if
            required by law or to protect our legal rights.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Data Security
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            We use industry-standard security measures to protect your data.
            However, no method of transmission over the internet is entirely
            secure, and we cannot guarantee absolute security.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Data Retention
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            We retain personal information for as long as necessary to fulfill
            the purposes outlined in this Privacy Policy, unless a longer
            retention period is required by law.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Your Rights
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            Depending on your jurisdiction, you may have rights related to your
            personal information, such as accessing, updating, or deleting it.
            To exercise these rights, please contact us at{" "}
            <Typography.Link
              className="underline text-slate-400"
              href="mailto:media@theartistnetwork.in"
            >
              media@theartistnetwork.in
            </Typography.Link>
            .
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Changes to This Privacy Policy
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page, and your continued use of the Website
            signifies acceptance of the updated terms.
          </Typography.Paragraph>

          <Typography.Title className="text-slate-500" level={5}>
            Contact Us
          </Typography.Title>
          <Typography.Paragraph className="text-slate-400">
            For questions or concerns about this Privacy Policy, please contact
            us at{" "}
            <Typography.Link
              className="underline text-slate-400"
              href="mailto:media@theartistnetwork.in"
            >
              media@theartistnetwork.in
            </Typography.Link>
            .
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
