import { Button, Form, Input, Space } from "antd";
import {
  isStrongPassword,
  confirmPasswordValidator,
} from "../../../utils/formValidator";

const Password = ({ form, onFinish, onBack }) => {
  return (
    <Form
      className="w-full"
      name="artistPasswordSignUp"
      onFinish={onFinish}
      form={form}
      layout="vertical"
      requiredMark="optional"
    >
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
          () => ({
            validator: isStrongPassword(),
          }),
        ]}
        hasFeedback
      >
        <Input.Password placeholder="********" />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label="Confirm Password"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator: confirmPasswordValidator(getFieldValue),
          }),
        ]}
      >
        <Input.Password placeholder="********" />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Sign Up
          </Button>
          <Button onClick={onBack}>Back</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default Password;
