import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Modal } from "antd";
import { selectLoggedInArtist } from "../../../../reducers/artist";
import { addTermsAndConditions } from "../../../../actions/artist";

const AddConditionModal = ({ open, closeModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const artistId = useSelector(selectLoggedInArtist)?.id;

  const handleSubmit = (values) => {
    dispatch(
      addTermsAndConditions({
        artistId,
        content: values.content,
      })
    );
    closeModal();
  };

  return (
    <Modal
      open={open}
      title="Add Condition"
      okText="Add"
      cancelText="Cancel"
      okButtonProps={{
        autoFocus: true,
        htmlType: "submit",
      }}
      onCancel={closeModal}
      destroyOnClose
      modalRender={(dom) => (
        <Form
          layout="vertical"
          form={form}
          name="addTermsAndConditionsModal"
          requiredMark="optional"
          clearOnDestroy
          onFinish={handleSubmit}
        >
          {dom}
        </Form>
      )}
    >
      <Form.Item
        name="content"
        label="Condition"
        rules={[
          {
            required: true,
            message: "Please input the condition!",
          },
          {
            max: 300,
            message: "Condition cannot be more than 300 letters!",
          },
        ]}
      >
        <Input placeholder="Client should provide the expenses for travel if the distance is too long." />
      </Form.Item>
    </Modal>
  );
};

export default AddConditionModal;
