const constants = {
  DEFAULT_ERROR: {
    CREATE_ARTIST: "Error while creating artist.",
    ARTIST_LOGIN: "Error while logging in artist.",
    CREATE_RESTAURANT: "Error while creating restaurant.",
    RESTAURANT_LOGIN: "Error while logging in restaurant.",
    FETCH_ARTISTS_BY_CITY_ID: "Error while fetching artists.",
    FETCH_ARTIST_BY_ID: "Error while fetching artist.",
    FETCH_CLIENT_BY_ID: "Error while fetching client.",
    FETCH_ADDRESSES_BY_ARTIST_ID: "Error while fetching addresses.",
    FETCH_CITIES: "Error while fetching cities.",
    CREATE_ADDRESS: "Error while creating address.",
    UPDATE_ADDRESS: "Error while updating address.",
    DELETE_ADDRESS: "Error while deleting address.",
    ADD_ARTIST_MEDIA: "Error while adding media.",
    DELETE_ARTIST_MEDIA: "Error while deleting media.",
    ADD_TERMS_AND_CONDITIONS: "Error while adding terms and conditions.",
    DELETE_TERMS_AND_CONDITIONS: "Error while deleting terms and conditions.",
    UPDATE_ABOUT_INFO: "Error while updating about section.",
    UPDATE_GENERAL_INFO: "Error while updating general section.",
    UPDATE_WORK_INFO: "Error while updating work section.",
    FETCH_BOOKINGS: "Error while fetching bookings.",
    FETCH_AVAILABILITIES: "Error while fetching artist availability times.",
    BOOKING: "Could not confirm your booking because of an unexpected error!",
    CANCEL_BOOKING: "Error while cancelling booking!",
    CONFIRM_BOOKING: "Error while confirming booking!",
  },
  DEFAULT_SUCCESS: {
    CREATE_ARTIST: "Successfully created new artist.",
    ARTIST_LOGIN: "Successfully logged in.",
    CREATE_RESTAURANT: "Successfully created new restaurant.",
    CREATE_ADDRESS: "Successfully created new address.",
    UPDATE_ADDRESS: "Successfully updated address.",
    DELETE_ADDRESS: "Successfully deleted address.",
    ADD_ARTIST_MEDIA: "Successfully added media.",
    DELETE_ARTIST_MEDIA: "Successfully deleted media.",
    ADD_TERMS_AND_CONDITIONS: "Successfully added terms and conditions.",
    DELETE_TERMS_AND_CONDITIONS: "Successfully deleted terms and conditions.",
    UPDATE_ABOUT_INFO: "Successfully updated about section.",
    UPDATE_GENERAL_INFO: "Successfully updated general section.",
    UPDATE_WORK_INFO: "Successfully updated work section.",
    BOOKING:
      "Your booking request has been sent to the Artist successfully! Our team will work with the Artist to confirm your booking. You will receive a confirmation call from our team within an <strong>3 hours</strong>.",
    CANCEL_BOOKING: "Successfully cancelled the booking.",
    CONFIRM_BOOKING: "Successfully confirmed the booking.",
  },
};

export default constants;
