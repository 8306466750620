import { Button, Carousel, Typography } from "antd";
import { Link } from "react-router-dom";
import { ROUTES_MAP } from "../../constants/common";
import singer from "../../images/performance.png";

const Home = () => {
  return (
    <div>
      <div className="w-full md:py-0 py-[50px] px-[50px] h-fit flex md:flex-row flex-col justify-center items-center">
        <div className="w-full md:h-[90vh] flex md:flex-row flex-col justify-around items-center gap-10">
          <div className="flex flex-col items-center w-full gap-4 md:w-1/2">
            <Typography.Title className="w-full font-extrabold font-[Montserrat] m-0 text-center md:text-left">
              Book Your Artist!
            </Typography.Title>
            <Typography.Paragraph className="m-0 text-center text-slate-400 md:text-left">
              Connect with talented artists all over India to bring your vision
              to life. Explore portfolios, and hire the perfect artist for your
              project. Start your creative journey today!
            </Typography.Paragraph>
            <div className="flex flex-col items-center w-full gap-2 md:flex-row lg:justify-start">
              <Link to={ROUTES_MAP.ARTISTS.path}>
                <Button
                  className="bg-black hover:bg-black px-14"
                  type="primary"
                  size="large"
                >
                  Discover Artists
                </Button>
              </Link>
            </div>
          </div>
          <div className="relative flex justify-center w-full md:w-[45%]">
            <img
              src={singer}
              alt="Singer"
              className="object-cover w-full rounded-lg"
            />
            <div
              className="absolute inset-0 rounded-lg pointer-events-none"
              style={{
                background:
                  "radial-gradient(circle at top left, rgba(255, 255, 255, 0.8), transparent 50%), radial-gradient(circle at top right, rgba(255, 255, 255, 0.8), transparent 50%), radial-gradient(circle at bottom left, rgba(255, 255, 255, 0.8), transparent 50%), radial-gradient(circle at bottom right, rgba(255, 255, 255, 0.8), transparent 50%)",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
