import { configureStore } from "@reduxjs/toolkit";
import addressReducer from "./reducers/address";
import artistReducer from "./reducers/artist";
import clientReducer from "./reducers/client";

export default configureStore({
  reducer: {
    address: addressReducer,
    artist: artistReducer,
    client: clientReducer,
  },
});
