import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Card, Divider, Typography, Upload, Button, Row } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { updateProfilePicture } from "../../../../../actions/client";
import noprofilepicture from "../../../../../images/noprofilepicture.jpeg";

const ProfilePicture = ({ client }) => {
  const dispatch = useDispatch();

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(updateProfilePicture({ clientId: client?.id, payload: formData }));
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      throw new Error("You can only upload image files!");
    }
    return isImage;
  };

  return (
    <Fragment>
      <Divider orientation="left">
        <Typography.Title level={5}>Profile Picture</Typography.Title>
      </Divider>

      <Row gutter={8} className="flex justify-center">
        <Card
          style={{
            width: 260,
          }}
          cover={
            <img
              alt="Client"
              src={client.profilePictureURL ?? noprofilepicture}
            />
          }
        >
          <ImgCrop
            aspect={1}
            modalTitle="Crop Image"
            beforeCrop={(file) => beforeUpload(file)}
          >
            <Upload
              className="flex justify-center w-full"
              showUploadList={false}
              customRequest={({ file, onSuccess, onError }) => {
                uploadImage(file);
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </ImgCrop>
        </Card>
      </Row>
    </Fragment>
  );
};

export default ProfilePicture;
