import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Typography } from "antd";
import { ROUTES_MAP } from "../../../constants/common";
import { artistLogin } from "../../../actions/artist";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSubmitHandler = async (payload) => {
    try {
      const result = await dispatch(artistLogin(payload));
      // Handle successful login (e.g., navigate)
      if (artistLogin.fulfilled.match(result))
        navigate(ROUTES_MAP.ARTIST_MY_PROFILE.path); // Replace with your desired route
    } catch (error) {}
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full pt-10 md:w-2/3">
        <Form
          className="w-full"
          name="artistLogin"
          onFinish={handleSubmitHandler}
          form={form}
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input placeholder="name@example.com" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="********" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="text-center">
        <Typography.Paragraph>
          Don't have an account?{" "}
          <Typography.Link
            onClick={() => navigate(ROUTES_MAP.ARTIST_SIGNUP.path)}
          >
            Signup!
          </Typography.Link>
        </Typography.Paragraph>
      </div>
    </div>
  );
};

export default LoginForm;
