import { useSelector } from "react-redux";
import { Button, Form, Input, Select } from "antd";
import { selectArtistTypes } from "../../../reducers/artist";

const { Option } = Select;

const BasicInfo = ({ form, onFinish }) => {
  const artistTypes = useSelector(selectArtistTypes);

  return (
    <Form
      className="w-full"
      name="artistBasicInfoSignUp"
      onFinish={onFinish}
      form={form}
      layout="vertical"
      requiredMark="optional"
    >
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[
          {
            required: true,
            message: "Please input your First Name!",
          },
          { max: 50, message: "First Name cannot be more than 50 letters" },
        ]}
      >
        <Input placeholder="Jane" />
      </Form.Item>

      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[
          { max: 50, message: "Last Name cannot be more than 50 letters" },
        ]}
      >
        <Input placeholder="Doe" />
      </Form.Item>

      <Form.Item
        name="gender"
        label="Gender"
        rules={[
          {
            required: true,
            message: "Please select your Gender!",
          },
        ]}
      >
        <Select placeholder="Female" allowClear>
          <Option value="MALE">Male</Option>
          <Option value="FEMALE">Female</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="typeId"
        label="Artist Type"
        rules={[
          {
            required: true,
            message: "Please select your Artist Type!",
          },
        ]}
      >
        <Select placeholder="Singer">
          {artistTypes?.map((artistType) => (
            <Option value={artistType.id}>{artistType.name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BasicInfo;
