import axios from "axios";
import routeConstants from "../constants/route";

// Enable sending cookies with requests
axios.defaults.withCredentials = true;

const API = axios.create({
  baseURL: routeConstants.BASE_URL,
  withCredentials: true,
});

export const createArtistAddress = (payload) =>
  API.post(
    `/${routeConstants.ROUTE.ARTIST}/${routeConstants.ROUTE.ADDRESS}`,
    payload
  );

export const updateArtistAddress = (payload) =>
  API.put(
    `/${routeConstants.ROUTE.ARTIST}/${routeConstants.ROUTE.ADDRESS}`,
    payload
  );

export const deleteArtistAddress = (addressId) =>
  API.delete(
    `/${routeConstants.ROUTE.ARTIST}/${routeConstants.ROUTE.ADDRESS}/${addressId}`
  );

export const fetchAddressesByArtistId = (id) =>
  API.get(
    `/${routeConstants.ROUTE.ARTIST}/${routeConstants.ROUTE.ADDRESS}/${id}`
  );

export const createClientAddress = (payload) =>
  API.post(
    `/${routeConstants.ROUTE.CLIENT}/${routeConstants.ROUTE.ADDRESS}`,
    payload
  );

export const updateClientAddress = (payload) =>
  API.put(
    `/${routeConstants.ROUTE.CLIENT}/${routeConstants.ROUTE.ADDRESS}`,
    payload
  );

export const deleteClientAddress = (addressId) =>
  API.delete(
    `/${routeConstants.ROUTE.CLIENT}/${routeConstants.ROUTE.ADDRESS}/${addressId}`
  );

export const fetchAddressesByClientId = (id) =>
  API.get(
    `/${routeConstants.ROUTE.CLIENT}/${routeConstants.ROUTE.ADDRESS}/${id}`
  );

export const fetchCities = () => API.get("/cities");
