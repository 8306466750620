import _ from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Spin, Result } from "antd";
import {
  selectEditProfileLoading,
  selectLoggedInArtist,
} from "../../../../reducers/artist";
import { fetchMetadata } from "../../../../actions/artist";
import Gallery from "./Gallery";
import TermsAndConditions from "./TermsAndConditions";
import About from "./About";
import General from "./General";
import Work from "./Work";
import ProfilePicture from "./ProfilePicture";

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const artist = useSelector(selectLoggedInArtist);
  const loading = useSelector(selectEditProfileLoading);

  console.log(artist);

  const artistId = artist?.id;
  const artistAwards = artist?.awards || [];
  const artistGenres = artist?.genres || [];
  const artistInstruments = artist?.instruments || [];
  const artistLanguages = artist?.languages || [];
  const artistEventTypes = artist?.eventTypes || [];
  const medias = artist?.medias || [];
  const termsAndConditions = artist?.termsAndConditions || [];

  useEffect(() => {
    dispatch(fetchMetadata());
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-[50vh]">
        <Spin size="large" />
      </div>
    );
  }

  if (_.isEmpty(artistId)) {
    return (
      <div className="flex justify-center w-full my-10">
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button type="primary" onClick={() => navigate("/")}>
              Back Home
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center w-full my-10">
      <div className="w-4/5">
        <ProfilePicture artist={artist} />
        <General artist={artist} />
        <Work artist={artist} />
        <About
          artistAwards={artistAwards}
          artistGenres={artistGenres}
          artistEventTypes={artistEventTypes}
          artistInstruments={artistInstruments}
          artistLanguages={artistLanguages}
        />
        <Gallery medias={medias} />
        <TermsAndConditions termsAndConditions={termsAndConditions} />
      </div>
    </div>
  );
};

export default EditProfile;
