import { createAsyncThunk } from "@reduxjs/toolkit";
import * as addressAPIs from "../api/address";
import { fetchClientById } from "./client";
import { fetchLoggedInArtist } from "./artist";

export const createArtistAddress = createAsyncThunk(
  "address/createArtistAddress",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await addressAPIs.createArtistAddress(payload);
      dispatch(fetchAddressesByArtistId(payload?.artistId));
      dispatch(fetchLoggedInArtist(payload?.artistId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateArtistAddress = createAsyncThunk(
  "address/updateArtistAddress",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await addressAPIs.updateArtistAddress(payload);
      dispatch(fetchAddressesByArtistId(payload?.artistId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteArtistAddress = createAsyncThunk(
  "address/deleteArtistAddress",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await addressAPIs.deleteArtistAddress(
        payload?.addressId
      );
      dispatch(fetchAddressesByArtistId(payload?.artistId));
      dispatch(fetchLoggedInArtist(payload?.artistId));
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAddressesByArtistId = createAsyncThunk(
  "address/fetchAddressesByArtistId",
  async (artistId, { rejectWithValue }) => {
    try {
      const response = await addressAPIs.fetchAddressesByArtistId(artistId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createClientAddress = createAsyncThunk(
  "address/createClientAddress",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await addressAPIs.createClientAddress(payload);
      dispatch(fetchAddressesByClientId(payload?.clientId));
      dispatch(fetchClientById(payload?.clientId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateClientAddress = createAsyncThunk(
  "address/updateClientAddress",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await addressAPIs.updateClientAddress(payload);
      dispatch(fetchAddressesByClientId(payload?.clientId));
      dispatch(fetchClientById(payload?.clientId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteClientAddress = createAsyncThunk(
  "address/deleteClientAddress",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await addressAPIs.deleteClientAddress(
        payload?.addressId
      );
      dispatch(fetchAddressesByClientId(payload?.clientId));
      dispatch(fetchClientById(payload?.clientId));
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAddressesByClientId = createAsyncThunk(
  "address/fetchAddressesByClientId",
  async (clientId, { rejectWithValue }) => {
    try {
      const response = await addressAPIs.fetchAddressesByClientId(clientId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchCities = createAsyncThunk(
  "address/fetchCities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await addressAPIs.fetchCities();
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
