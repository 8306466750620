import _ from "lodash";
import { Fragment } from "react";
import { Col, Divider, Row, Typography } from "antd";
import Booking from "./Booking";

const Section = ({ title, bookings }) => {
  if (_.isEmpty(bookings)) {
    return;
  }

  return (
    <Fragment>
      <Divider orientation="left">
        <Typography.Title level={3}>{title}</Typography.Title>
      </Divider>

      <Row gutter={24} justify="space-around">
        {bookings.map((booking, colIndex) => (
          <Col
            className="flex justify-center mb-8"
            xs={24} // Full width on extra small screens
            sm={12} // Half width on small screens
            md={12} // One-third width on medium screens
            lg={8} // One-fourth width on large screens
            key={colIndex}
          >
            <Booking booking={booking} />
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

export default Section;
