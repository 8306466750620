import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Form, InputNumber, Modal, Select } from "antd";
import {
  selectArtistTypes,
  selectLoggedInArtist,
} from "../../../../reducers/artist";
import { updateWorkInfo } from "../../../../actions/artist";

const { Option } = Select;

const UpdateWorkInfoModal = ({ open, closeModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const artist = useSelector(selectLoggedInArtist);
  const artistTypes = useSelector(selectArtistTypes);

  const handleSubmit = (values) => {
    dispatch(
      updateWorkInfo({
        ...values,
        artistId: artist.id,
      })
    );
    form.resetFields();
    closeModal();
  };

  const handleClose = () => {
    form.resetFields();
    closeModal();
  };

  return (
    <Modal
      open={open}
      title="Work"
      okText="Update"
      cancelText="Cancel"
      okButtonProps={{
        autoFocus: true,
        htmlType: "submit",
      }}
      onCancel={handleClose}
      destroyOnClose
      modalRender={(dom) => (
        <Form
          layout="vertical"
          form={form}
          name="updateWorkInfoModal"
          requiredMark="optional"
          initialValues={{
            typeId: artist.typeId,
            workStatus: artist.workStatus,
            price: artist.price,
          }}
          onFinish={handleSubmit}
        >
          {dom}
        </Form>
      )}
    >
      <Form.Item
        name="typeId"
        label="Artist Type"
        rules={[
          {
            required: true,
            message: "Please select your Artist Type!",
          },
        ]}
      >
        <Select placeholder="Singer">
          {artistTypes.map((type) => (
            <Option value={type.id}>{type.name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="workStatus"
        label="Work Status"
        rules={[
          {
            required: true,
            message: "Please select your Work Status!",
          },
        ]}
        extra="The work status has to be ACTIVE to show your account to the clients. This won't affect the already made bookings."
      >
        <Select placeholder="Active">
          <Option value="ACTIVE">Active</Option>
          <Option value="INACTIVE">Inactive</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="price"
        label="Price"
        rules={[
          {
            type: "number",
            message: "Price has to be a number!",
          },
          {
            required: true,
            message: "Please enter your Price!",
          },
          {
            validator: (rule, value) => {
              if (value && value < 100) {
                return Promise.reject("Price must be at least 100");
              }
              return Promise.resolve();
            },
          },
        ]}
        extra="This amount will be shown to your client. The updated price won't affect the already confirmed booking prices."
      >
        <InputNumber style={{ width: "100%" }} placeholder="20000" />
      </Form.Item>
    </Modal>
  );
};

export default UpdateWorkInfoModal;
