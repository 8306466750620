import Icon from "@ant-design/icons";
import customIcon from "../../../images/icons/guitar.png";

const CustomIcon = () => (
  <img
    src={customIcon}
    alt="Instrument Icon"
    style={{ width: "35px" }} // You can adjust the size here
  />
);

const InstrumentIcon = () => <Icon component={CustomIcon} />;

export default InstrumentIcon;
