import Icon from "@ant-design/icons";
import customIcon from "../../../images/icons/language.png";

const CustomIcon = () => (
  <img
    src={customIcon}
    alt="Language Icon"
    style={{ width: "35px" }} // You can adjust the size here
  />
);

const LanguageIcon = () => <Icon component={CustomIcon} />;

export default LanguageIcon;
