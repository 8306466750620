import { useNavigate } from "react-router-dom";
import { Collapse, Divider, Typography } from "antd";
import { ROUTES_MAP } from "../../constants/common";
import ContactCard from "../Contact/Card";

const { Panel } = Collapse;
const { Title, Text, Link } = Typography;

const FAQ = () => {
  const navigate = useNavigate();

  const FAQ_DATA = [
    {
      key: "1",
      question: "Can I book an artist from outside my city/state?",
      answer:
        "Yes, you can! However, clients are responsible for the artist's travel and accommodation expenses.",
    },
    {
      key: "2",
      question: "What if I need to cancel an artist?",
      answer: (
        <>
          For details on cancellation terms and refund eligibility, please visit
          our{" "}
          <Link
            onClick={() => navigate(ROUTES_MAP.REFUND_AND_CANCELLATION.path)}
          >
            Cancellation Policy
          </Link>{" "}
          page.
        </>
      ),
    },
    {
      key: "3",
      question: "How do I find the right artist?",
      answer: (
        <>
          You can visit our{" "}
          <Link onClick={() => navigate(ROUTES_MAP.ARTISTS.path)}>
            Discover Artists
          </Link>{" "}
          page to browse all artists and view their portfolios, which include
          videos, bios, and an "About" section to help you make the best choice.
        </>
      ),
    },
    {
      key: "4",
      question: "How do I know if an artist is good?",
      answer:
        "We take quality seriously! Every artist on our platform is verified by our team through a thorough review process. Additionally, we encourage client feedback after each performance, which helps us continuously monitor and maintain high standards for all artists listed on our platform.",
    },
    {
      key: "5",
      question: "What if the artist arrives late?",
      answer:
        "If an artist arrives late, clients have the right to deduct 10% from their total fee.",
    },
    {
      key: "6",
      question: "How do I add videos links?",
      answer:
        "Upload your performance videos to YouTube and add the links to your portfolio. Please note, videos can only be uploaded via a laptop by following the steps provided on the 'Edit Profile' page.",
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center w-full my-10">
      <div className="flex flex-col items-center w-4/5">
        <Divider orientation="left">
          <Title level={3}>FAQ</Title>
        </Divider>

        <Collapse className="w-full" accordion>
          {FAQ_DATA.map(({ key, question, answer }) => (
            <Panel header={question} key={key}>
              <Text>{answer}</Text>
            </Panel>
          ))}
        </Collapse>

        <div className="flex justify-center w-full mt-10">
          <ContactCard />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
