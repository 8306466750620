import axios from "axios";
import routeConstants from "../constants/route";

// Enable sending cookies with requests
axios.defaults.withCredentials = true;

const API = axios.create({
  baseURL: routeConstants.BASE_URL,
  withCredentials: true,
});

export const validateToken = () => API.post("/auth/validate-token");

export const logout = () => API.post("/auth/logout");
