import _ from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Steps, Typography } from "antd";
import BasicInfo from "./BasicInfo";
import Contact from "./Contact";
import Password from "./Password";
import { ROUTES_MAP } from "../../../constants/common";
import { createArtist } from "../../../actions/artist";

const SignupForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [basicInfoForm] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({});

  const onStepChange = (value) => {
    setCurrent(value);
  };

  const currentForm =
    current === 0 ? (
      <BasicInfo
        form={basicInfoForm}
        onFinish={async () => {
          try {
            await basicInfoForm.validateFields();
            setFormData((prevState) => ({
              ...prevState,
              ...basicInfoForm.getFieldsValue(),
            }));
            onStepChange(current + 1);
          } catch (error) {}
        }}
      />
    ) : current === 1 ? (
      <Contact
        form={contactForm}
        onFinish={async () => {
          try {
            await contactForm.validateFields();
            setFormData((prevState) => ({
              ...prevState,
              ...contactForm.getFieldsValue(),
            }));
            onStepChange(current + 1);
          } catch (error) {}
        }}
        onBack={() => onStepChange(current - 1)}
      />
    ) : current === 2 ? (
      <Password
        form={passwordForm}
        onFinish={async () => {
          try {
            await passwordForm.validateFields();
            dispatch(
              createArtist({
                ...(formData || {}),
                ...passwordForm.getFieldsValue(),
              })
            );
            setFormData({});
          } catch (error) {}
        }}
        onBack={() => onStepChange(current - 1)}
      />
    ) : null;

  return (
    <div className="flex flex-col items-center w-full">
      <div className="hidden w-full md:block">
        <Steps
          current={current}
          items={[
            {
              title: "General",
            },
            {
              title: "Contact",
            },
            {
              title: "Password",
            },
          ]}
        />
      </div>
      <div className="w-full pt-10 md:w-2/3">{currentForm}</div>
      <div>
        <Typography.Paragraph>
          Already have an account?{" "}
          <Typography.Link
            onClick={() => navigate(ROUTES_MAP.ARTIST_LOGIN.path)}
          >
            Login!
          </Typography.Link>
        </Typography.Paragraph>
      </div>
    </div>
  );
};

export default SignupForm;
