import { Typography } from "antd";

const AboutItem = ({ icon, title, content }) => {
  return (
    <div className="flex w-full h-fit">
      <div className="flex items-center h-full mr-4 w-[30px]">{icon}</div>
      <div className="flex-1">
        <Typography.Paragraph className="m-0 font-semibold">
          {title} -
        </Typography.Paragraph>
        <Typography.Paragraph className="m-0">{content}</Typography.Paragraph>
      </div>
    </div>
  );
};

export default AboutItem;
