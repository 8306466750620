import { Input } from "antd";

const Search = ({ onSearch }) => {
  return (
    <Input.Search
      placeholder="Search Artist"
      allowClear
      size="large"
      onSearch={onSearch}
    />
  );
};

export default Search;
