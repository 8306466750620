import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { clearLoggedInArtist } from "../../../reducers/artist";
import { clearLoggedInClient } from "../../../reducers/client";
import { ROUTES_MAP } from "../../../constants/common";
import * as authAPIs from "../../../api/auth";

const LogoutModal = ({ isModalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await authAPIs.logout();
      dispatch(clearLoggedInArtist());
      dispatch(clearLoggedInClient());
      closeModal();
      navigate(ROUTES_MAP.HOME.path);
    } catch (e) {}
  };

  return (
    <Modal
      title="Logout"
      open={isModalOpen}
      onOk={handleLogout}
      onCancel={closeModal}
      okText="Logout"
      okButtonProps={{
        type: "primary",
        danger: true,
      }}
      centered
    >
      Are you sure you want to logout?
    </Modal>
  );
};

export default LogoutModal;
