export const ROUTES_MAP = {
  HOME: { key: "home", path: "/", name: "Home" },
  EVENTS: { key: "events", path: "/events", name: "Live Music" },
  ARTISTS: { key: "artists", path: "/artists", name: "Artists" },
  ARTIST: { key: "artist", path: "/artist", name: "Artist" },
  SERVICES: { key: "services", path: "/services", name: "Services" },
  CONTACT: { key: "contact", path: "/contact", name: "Contact" },
  ARTIST_LOGIN: {
    key: "artist-login",
    path: "/artist/login",
    name: "Login",
  },
  ARTIST_SIGNUP: {
    key: "artist-signup",
    path: "/artist/signup",
    name: "Signup",
  },
  ARTIST_MY_PROFILE: {
    key: "artist-my-profile",
    path: "/artist/my-profile",
    name: "My Profile",
  },
  ARTIST_EDIT_PROFILE: {
    key: "artist-edit-profile",
    path: "/artist/edit-profile",
    name: "Edit Profile",
  },
  ARTIST_BOOKINGS: {
    key: "artist-bookings",
    path: "/artist/bookings",
    name: "Bookings",
  },
  ARTIST_ADDRESSES: {
    key: "artist-addresses",
    path: "/artist/addressess",
    name: "Addresses",
  },
  RESTAURANT_LOGIN: {
    key: "restaurant-login",
    path: "/restaurant/login",
    name: "Login",
  },
  RESTAURANT_SIGNUP: {
    key: "restaurant-signup",
    path: "/restaurant/signup",
    name: "Signup",
  },
  INDIVIDUAL_LOGIN: {
    key: "individual-login",
    path: "/individual/login",
    name: "Login",
  },
  INDIVIDUAL_SIGNUP: {
    key: "individual-signup",
    path: "/individual/signup",
    name: "Signup",
  },
  CLIENT_MY_PROFILE: {
    key: "client-my-profile",
    path: "/client/my-profile",
    name: "My Profile",
  },
  CLIENT_EDIT_PROFILE: {
    key: "client-edit-profile",
    path: "/client/edit-profile",
    name: "Edit Profile",
  },
  CLIENT_BOOKINGS: {
    key: "client-bookings",
    path: "/client/bookings",
    name: "Bookings",
  },
  CLIENT_ADDRESSES: {
    key: "client-addresses",
    path: "/client/addressess",
    name: "Addresses",
  },
  FAQ: {
    key: "faq",
    path: "/faq",
    name: "FAQ",
  },
  BLOGS: {
    key: "blogs",
    path: "/blogs",
    name: "Blogs",
  },
  PRIVACY_POLICY: {
    key: "privacy-policy",
    path: "/privacy-policy",
    name: "Privacy Policy",
  },
  REFUND_AND_CANCELLATION: {
    key: "refund-and-cancellation",
    path: "/refund-and-cancellation",
    name: "Refund & Cancellation",
  },
  TERMS_AND_CONDITIONS: {
    key: "terms-and-conditions",
    path: "/terms-and-conditions",
    name: "Terms & Conditions",
  },
};

export const BOOKING_STATUS = {
  CONFIRMED: {
    id: "CONFIRMED",
    name: "Confirmed",
    color: {
      background: "rgba(82, 196, 26, 0.1)", // light green
      text: "#52c41a", // green
    },
  },
  CANCELLED_BY_ARTIST: {
    id: "CANCELLED_BY_ARTIST",
    name: "Cancelled by Artist",
    color: {
      background: "rgba(250, 84, 28, 0.1)", // light volcano
      text: "#fa541c", // volcano (red-orange)
    },
  },
  CANCELLED_BY_CLIENT: {
    id: "CANCELLED_BY_CLIENT",
    name: "Cancelled by Client",
    color: {
      background: "rgba(250, 84, 28, 0.1)", // light volcano
      text: "#fa541c", // volcano (red-orange)
    },
  },
  ADVANCE_PAYMENT_DONE: {
    id: "ADVANCE_PAYMENT_DONE",
    name: "Advance Payment Done",
    color: {
      background: "rgba(250, 173, 20, 0.1)", // light gold
      text: "#faad14", // gold (yellow)
    },
  },
  PENDING: {
    id: "PENDING",
    name: "Pending from Artist",
    color: {
      background: "rgba(24, 144, 255, 0.1)", // light blue
      text: "#1890ff", // blue
    },
  },
  COMPLETED: {
    id: "COMPLETED",
    name: "Completed",
    color: {
      background: "rgba(82, 196, 26, 0.1)", // light green
      text: "#52c41a", // green
    },
  },
};

const constants = {
  DATA_TYPES: {
    STRING: "string",
  },
  DEFAULT_PAGE_SIZES: {
    ARTISTS: 20,
  },
  SOCIAL_MEDIA: {
    TWITTER: {
      NAME: "Twitter",
      LINK: "https://x.com/tan_official_",
    },
    INSTAGRAM: {
      NAME: "Instagram",
      LINK: "https://www.instagram.com/the_artist_network_official/",
    },
    FACEBOOK: {
      NAME: "Facebook",
      LINK: "https://www.instagram.com/the_artist_network_official/",
    },
    LINKEDIN: {
      NAME: "LinkedIn",
      LINK: "https://www.linkedin.com/company/the-artist-network-official/",
    },
    YOUTUBE: {
      NAME: "YouTube",
      LINK: "https://youtube.com/@theartistnetwork_official?si=3-zU_CpnLXUnTKEf",
    },
  },
  CITIES: {
    MUMBAI: {
      id: 1,
      name: "Mumbai",
    },
    PUNE: {
      id: 2,
      name: "Pune",
    },
    HYDERABAD: {
      id: 8,
      name: "Hyderabad",
    },
    DELHI: {
      id: 9,
      name: "Delhi",
    },
    BANGALORE: {
      id: 4,
      name: "Bangalore",
    },
  },
  ARTIST_TYPES: {
    GUITARIST: "GUITARIST",
    SINGER: "SINGER",
    DRUMMER: "DRUMMER",
    DANCER: "DANCER",
    BAND: "BAND",
  },
  CLIENT_TYPES: {
    RESTAURANT: {
      id: 1,
      name: "Restaurant",
    },
    INDIVIDUAL: {
      id: 2,
      name: "Individual",
    },
  },
};

export default constants;
