import { useNavigate } from "react-router-dom";
import { Divider, Space, Typography } from "antd";
import {
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import commonConstants, { ROUTES_MAP } from "../../constants/common";
import artistNetwork from "../../images/theartistnetwork.png";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center w-full mb-12">
      <div className="w-4/5 md:w-3/4">
        <Divider />

        <div className="w-full md:h-[30vh] flex md:flex-row flex-col-reverse justify-around items-center">
          <div className="flex flex-col items-center justify-center w-full h-full md:w-2/3">
            <img
              className="w-[300px]"
              src={artistNetwork}
              alt="The Artist Network"
            />
            <Typography.Paragraph className="text-center text-slate-400">
              Hire the best artists from our talented network
            </Typography.Paragraph>
            <Space size="middle">
              <a
                className="no-underline cursor-auto text-inherit"
                href={commonConstants.SOCIAL_MEDIA.TWITTER.LINK}
                target="_blank"
              >
                <TwitterOutlined className="hover:cursor-pointer" />
              </a>
              <a
                className="no-underline cursor-auto text-inherit"
                href={commonConstants.SOCIAL_MEDIA.INSTAGRAM.LINK}
                target="_blank"
              >
                <InstagramOutlined className="hover:cursor-pointer" />
              </a>
              <a
                className="no-underline cursor-auto text-inherit"
                href={commonConstants.SOCIAL_MEDIA.FACEBOOK.LINK}
                target="_blank"
              >
                <FacebookFilled className="hover:cursor-pointer" />
              </a>
              <a
                className="no-underline cursor-auto text-inherit"
                href={commonConstants.SOCIAL_MEDIA.LINKEDIN.LINK}
                target="_blank"
              >
                <LinkedinFilled className="hover:cursor-pointer" />
              </a>
              <a
                className="no-underline cursor-auto text-inherit"
                href={commonConstants.SOCIAL_MEDIA.YOUTUBE.LINK}
                target="_blank"
              >
                <YoutubeOutlined className="hover:cursor-pointer" />
              </a>
            </Space>
          </div>

          <div className="flex flex-col w-full gap-4 text-center md:flex-row md:justify-around md:text-left md:gap-0">
            <Space direction="vertical" size="small">
              <Typography.Paragraph className="m-0" strong>
                Support
              </Typography.Paragraph>
              <Typography.Paragraph
                className="m-0 text-slate-400 hover:cursor-pointer"
                onClick={() => navigate(ROUTES_MAP.FAQ.path)}
              >
                {ROUTES_MAP.FAQ.name}
              </Typography.Paragraph>
              <Typography.Paragraph
                className="m-0 text-slate-400 hover:cursor-pointer"
                onClick={() => navigate(ROUTES_MAP.CONTACT.path)}
              >
                {ROUTES_MAP.CONTACT.name}
              </Typography.Paragraph>
            </Space>

            <Space direction="vertical" size="small">
              <Typography.Paragraph className="m-0" strong>
                Company
              </Typography.Paragraph>
              <Typography.Paragraph
                className="m-0 text-slate-400 hover:cursor-pointer"
                onClick={() => navigate(ROUTES_MAP.HOME.path)}
              >
                {ROUTES_MAP.HOME.name}
              </Typography.Paragraph>
              <Typography.Paragraph
                className="m-0 text-slate-400 hover:cursor-pointer"
                onClick={() => navigate(ROUTES_MAP.ARTISTS.path)}
              >
                {ROUTES_MAP.ARTISTS.name}
              </Typography.Paragraph>
              <Typography.Paragraph
                className="m-0 text-slate-400 hover:cursor-pointer"
                onClick={() => navigate(ROUTES_MAP.BLOGS.path)}
              >
                {ROUTES_MAP.BLOGS.name}
              </Typography.Paragraph>
              {/* <Typography.Paragraph className="m-0 text-slate-400">
                About Us
              </Typography.Paragraph> */}
            </Space>

            <Space direction="vertical" size="small">
              <Typography.Paragraph className="m-0" strong>
                Legal
              </Typography.Paragraph>
              <Typography.Paragraph
                className="m-0 text-slate-400 hover:cursor-pointer"
                onClick={() => navigate(ROUTES_MAP.PRIVACY_POLICY.path)}
              >
                {ROUTES_MAP.PRIVACY_POLICY.name}
              </Typography.Paragraph>
              <Typography.Paragraph
                className="m-0 text-slate-400 hover:cursor-pointer"
                onClick={() =>
                  navigate(ROUTES_MAP.REFUND_AND_CANCELLATION.path)
                }
              >
                {ROUTES_MAP.REFUND_AND_CANCELLATION.name}
              </Typography.Paragraph>
              <Typography.Paragraph
                className="m-0 text-slate-400 hover:cursor-pointer"
                onClick={() => navigate(ROUTES_MAP.TERMS_AND_CONDITIONS.path)}
              >
                {ROUTES_MAP.TERMS_AND_CONDITIONS.name}
              </Typography.Paragraph>
            </Space>
          </div>
        </div>

        <Divider />

        <div className="flex justify-center w-full text-center">
          <Typography.Paragraph className="m-0 text-slate-400">
            © 2024 The Artist Network. All rights reserved.
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  );
};

export default Footer;
