import _ from "lodash";
import { Alert, Spin } from "antd";
import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchArtistById } from "../../../../actions/artist";
import {
  selectArtist,
  selectArtistLoading,
  selectArtistError,
  clearArtist,
} from "../../../../reducers/artist";
import Artist from "./Artist";

const Profile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const artistId = useParams()?.id;
  const artist = useSelector(selectArtist);
  const loading = useSelector(selectArtistLoading);
  const error = useSelector(selectArtistError);

  useEffect(() => {
    dispatch(fetchArtistById(artistId));
  }, []);

  useEffect(() => {
    // Reset artist state when the component unmounts or the page changes
    return () => {
      dispatch(clearArtist());
    };
  }, [location.pathname]); // Triggers whenever the route changes

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-[50vh]">
        <Spin size="large" />
      </div>
    );
  }

  if (!_.isEmpty(error)) {
    return (
      <div className="flex justify-center w-full">
        <Alert message={error} type="error" />
      </div>
    );
  }

  return <Artist artist={artist} />;
};

export default Profile;
