import { useEffect } from "react";
import { fetchCities } from "../../actions/address";
import Artists from "./Artists";
import Navbar from "./Navbar";
import { useDispatch } from "react-redux";

const SearchScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCities());
  }, []);

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-4/5 my-6">
        <Navbar />
      </div>
      <div>
        <Artists />
      </div>
    </div>
  );
};

export default SearchScreen;
