import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Spin, Result, Typography } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import {
  selectLoggedInClient,
  selectIndividualLoginLoading,
} from "../../../../reducers/client";
import { ROUTES_MAP } from "../../../../constants/common";
import LoginForm from "./LoginForm";
import client from "../../../../images/icons/client.png";

const Login = () => {
  const navigate = useNavigate();
  const loading = useSelector(selectIndividualLoginLoading);
  const loggedInClient = useSelector(selectLoggedInClient);

  if (loggedInClient) {
    return (
      <Result
        icon={<SmileOutlined />}
        title="You are already logged in!"
        extra={
          <Button type="primary" onClick={() => navigate(ROUTES_MAP.HOME.path)}>
            Back Home
          </Button>
        }
      />
    );
  }

  return (
    <div className="flex flex-row justify-center w-full">
      <div className="w-full min-h-[90vh] pt-4 flex md:flex-row flex-col justify-center items-center gap-6">
        <div className="flex flex-col items-center w-full gap-4 px-8 md:w-1/3 md:px-0 md:justify-center">
          <img className="w-[100px] h-[100px]" src={client} alt="Client" />
          <Typography.Title className="font-extrabold font-[Montserrat] m-0 text-center">
            Private Individual Login
          </Typography.Title>
          <Typography.Paragraph className="w-full m-0 text-center text-slate-400">
            Connect with talented artists all over India to bring your events to
            life! Make your experiences memorable with the artists from our
            talent pool.
          </Typography.Paragraph>
        </div>
        <div className="flex flex-col items-center justify-center w-full md:w-1/2">
          <div className="w-2/3">
            {loading ? (
              <div className="w-full text-center">
                <Spin indicator={<LoadingOutlined spin />} size="large" />
              </div>
            ) : (
              <LoginForm />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
