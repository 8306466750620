import _ from "lodash";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Divider, Result, Spin, Typography } from "antd";
import {
  selectClientBookings,
  selectFetchBookingsLoading,
  selectFetchBookingsErrorMessage,
  selectLoggedInClient,
} from "../../../reducers/client";
import { BOOKING_STATUS, ROUTES_MAP } from "../../../constants/common";
import { fetchBookingsByClientId } from "../../../actions/client";
import Section from "./Section";

const Bookings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientId = useSelector(selectLoggedInClient)?.id;
  const loading = useSelector(selectFetchBookingsLoading);
  const fetchBookingsErrorMessage = useSelector(
    selectFetchBookingsErrorMessage
  );
  const bookings = useSelector(selectClientBookings);

  useEffect(() => {
    if (!_.isEmpty(clientId)) dispatch(fetchBookingsByClientId(clientId));
  }, [clientId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-[50vh]">
        <Spin size="large" />
      </div>
    );
  }

  if (!_.isEmpty(fetchBookingsErrorMessage)) {
    return (
      <div className="flex justify-center w-full my-10">
        <Alert message={fetchBookingsErrorMessage} type="error" />
      </div>
    );
  }

  if (_.isEmpty(clientId)) {
    return (
      <div className="flex justify-center w-full my-10">
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button
              type="primary"
              onClick={() => navigate(ROUTES_MAP.HOME.path)}
            >
              Back Home
            </Button>
          }
        />
      </div>
    );
  }

  const sortedBookings = [...bookings].sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  const upcomingBookings = sortedBookings.filter(
    (booking) =>
      booking?.status === BOOKING_STATUS.PENDING.id ||
      booking?.status === BOOKING_STATUS.ADVANCE_PAYMENT_DONE.id ||
      booking?.status === BOOKING_STATUS.CONFIRMED.id
  );
  const completedBookings = sortedBookings.filter(
    (booking) => booking?.status === BOOKING_STATUS.COMPLETED.id
  );
  const cancelledBookings = sortedBookings.filter(
    (booking) =>
      booking?.status === BOOKING_STATUS.CANCELLED_BY_ARTIST.id ||
      booking?.status === BOOKING_STATUS.CANCELLED_BY_CLIENT.id
  );

  return (
    <div className="flex flex-col items-center justify-center w-full my-10">
      <div className="w-4/5">
        {_.isEmpty(upcomingBookings) &&
        _.isEmpty(completedBookings) &&
        _.isEmpty(cancelledBookings) ? (
          <Fragment>
            <Divider orientation="left">
              <Typography.Title level={3}>Bookings</Typography.Title>
            </Divider>

            <div className="flex justify-center w-full my-10">
              <Alert message="No Bookings at the moment!" type="info" />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Section title="Upcoming Bookings" bookings={upcomingBookings} />
            <Section title="Completed Bookings" bookings={completedBookings} />
            <Section title="Cancelled Bookings" bookings={cancelledBookings} />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Bookings;
