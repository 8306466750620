import Icon from "@ant-design/icons";
import customIcon from "../../../images/icons/phone.png";

const CustomIcon = () => (
  <img
    src={customIcon}
    alt="Contact Icon"
    style={{ width: "35px" }} // You can adjust the size here
  />
);

const ContactIcon = () => <Icon component={CustomIcon} />;

export default ContactIcon;
