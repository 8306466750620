import _ from "lodash";
import validator from "validator";

export const isStrongPassword = () => (rule, value) =>
  validator.isStrongPassword(value, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  })
    ? Promise.resolve()
    : Promise.reject(
        new Error(
          "Password must be at least 8 characters long, include 1 lowercase letter, 1 uppercase letter, 1 number, and 1 symbol."
        )
      );

export const confirmPasswordValidator = (getFieldValue) => (_, value) => {
  if (!value || getFieldValue("password") === value) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error("The new password that you entered do not match!")
  );
};

export const isStringNumeric = (label) => (rule, value) =>
  _.isString(value) && /^\d+$/.test(value)
    ? Promise.resolve()
    : Promise.reject(new Error(`${label} should contain only numeric digits!`));
