import { Button, Form, Input, Select, Space } from "antd";
import { isStringNumeric } from "../../../utils/formValidator";

const { Option } = Select;

const Contact = ({ form, onFinish, onBack }) => {
  return (
    <Form
      className="w-full"
      name="artistContactSignUp"
      onFinish={onFinish}
      form={form}
      layout="vertical"
      requiredMark="optional"
    >
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input placeholder="name@example.com" />
      </Form.Item>

      <Form.Item
        name="contact"
        label="Phone Number"
        rules={[
          { required: true, message: "Please input your phone number!" },
          {
            len: 10,
            message: "Phone Number should be of length 10!",
          },
          () => ({
            validator: isStringNumeric("Phone Number"),
          }),
        ]}
      >
        <Input
          addonBefore={
            <Form.Item name="prefix" noStyle>
              <Select defaultValue="91" style={{ width: 70 }}>
                <Option value="91">+91</Option>
              </Select>
            </Form.Item>
          }
          placeholder="90324XXXXX"
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
          <Button onClick={onBack}>Back</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default Contact;
