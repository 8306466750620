import { useDispatch, useSelector } from "react-redux";
import { Alert, Typography, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  clearCreateArtistSuccessMessage,
  selectCreateArtistLoading,
  selectCreateArtistSuccessMessage,
} from "../../../reducers/artist";
import SignupForm from "./SignupForm";
import calendar from "../../../images/icons/calendar.png";
import { useEffect } from "react";
import { fetchMetadata } from "../../../actions/artist";

const ArtistSignup = () => {
  const dispatch = useDispatch();
  const createArtistLoading = useSelector(selectCreateArtistLoading);
  const createArtistSuccessMessage = useSelector(
    selectCreateArtistSuccessMessage
  );

  useEffect(() => {
    dispatch(fetchMetadata());
  }, []);

  return (
    <div className="w-full min-h-[90vh] pt-4 flex flex-auto md:flex-row flex-col-reverse justify-center items-center gap-6">
      <div className="flex items-center justify-around w-full md:w-1/2">
        <div className="w-2/3">
          {createArtistLoading ? (
            <Spin indicator={<LoadingOutlined spin />} size="large" />
          ) : createArtistSuccessMessage ? (
            <Alert
              message="Success!"
              showIcon
              description="Your account has been registered. Our representative will reach out to you and verify your profile offline. Thank you for your interest in The Artist Network!"
              type="success"
              closable
              onClose={() => dispatch(clearCreateArtistSuccessMessage())}
            />
          ) : (
            <SignupForm />
          )}
        </div>
      </div>
      <div className="w-full md:w-1/3">
        <div className="flex flex-col gap-4 px-8 md:px-0">
          <div className="flex justify-center w-full">
            <img
              className="w-[100px] h-[100px]"
              src={calendar}
              alt="Calendar"
            />
          </div>
          <Typography.Title className="font-extrabold font-[Montserrat] m-0 text-center">
            Get your first gig!
          </Typography.Title>
          <Typography.Paragraph className="m-0 text-center text-slate-400">
            Connect with talented artists all over India to bring your vision to
            life. Explore portfolios, and hire the perfect artist for your
            project. Start your creative journey today!
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  );
};

export default ArtistSignup;
