import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Modal, Select } from "antd";
import { selectLoggedInArtist } from "../../../../reducers/artist";
import { isStringNumeric } from "../../../../utils/formValidator";
import { updateGeneralInfo } from "../../../../actions/artist";

const { Option } = Select;

const UpdateGeneralInfoModal = ({ open, closeModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const artist = useSelector(selectLoggedInArtist);

  const handleSubmit = (values) => {
    dispatch(
      updateGeneralInfo({
        ...values,
        artistId: artist.id,
      })
    );
    form.resetFields();
    closeModal();
  };

  const handleClose = () => {
    form.resetFields();
    closeModal();
  };

  return (
    <Modal
      open={open}
      title="General"
      okText="Update"
      cancelText="Cancel"
      okButtonProps={{
        autoFocus: true,
        htmlType: "submit",
      }}
      onCancel={handleClose}
      destroyOnClose
      centered
      modalRender={(dom) => (
        <Form
          layout="vertical"
          form={form}
          name="updateGeneralInfoModal"
          requiredMark="optional"
          initialValues={{
            firstName: artist.firstName,
            lastName: artist.lastName,
            email: artist.email,
            gender: artist.gender,
            contact: artist.contact,
            bio: artist.bio,
          }}
          onFinish={handleSubmit}
        >
          {dom}
        </Form>
      )}
    >
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[
          {
            required: true,
            message: "Please input your First Name!",
          },
          {
            max: 20,
            message: "First Name cannot be longer than 20 letters!",
          },
        ]}
      >
        <Input placeholder="Jane" />
      </Form.Item>

      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[
          {
            max: 20,
            message: "Last Name cannot be longer than 20 letters!",
          },
        ]}
      >
        <Input placeholder="Doe" />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input placeholder="name@example.com" disabled />
      </Form.Item>

      <Form.Item
        name="gender"
        label="Gender"
        rules={[
          {
            required: true,
            message: "Please select your Gender!",
          },
        ]}
      >
        <Select placeholder="Female" allowClear>
          <Option value="MALE">Male</Option>
          <Option value="FEMALE">Female</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="contact"
        label="Phone Number"
        rules={[
          { required: true, message: "Please input your phone number!" },
          {
            len: 10,
            message: "Phone Number should be of length 10!",
          },
          () => ({
            validator: isStringNumeric("Phone Number"),
          }),
        ]}
      >
        <Input
          addonBefore={
            <Form.Item name="prefix" noStyle>
              <Select defaultValue="91" style={{ width: 70 }}>
                <Option value="91">+91</Option>
              </Select>
            </Form.Item>
          }
          placeholder="90324XXXXX"
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item name="bio" label="Bio">
        <Input.TextArea
          type="textarea"
          rows={3}
          maxLength={500}
          placeholder="Hi, This is Jane Doe. I'm an artist....."
        />
      </Form.Item>
    </Modal>
  );
};

export default UpdateGeneralInfoModal;
