const constants = {
  BASE_URL: "/api",
  ROUTE: {
    ARTIST: "artist",
    CLIENT: "client",
    RESTAURANT: "restaurant",
    INDIVIDUAL: "individual",
    ADDRESS: "address",
    MEDIA: "media",
    TERMS_AND_CONDITIONS: "terms-and-conditions",
  },
};

export default constants;
