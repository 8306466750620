import { Fragment, useState } from "react";
import { Button, Card, Divider, Row, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { formatToRupee } from "../../../../../utils/common";
import AboutItem from "../../../../Custom/AboutItem";
import UserIcon from "../../../../Custom/Icon/UserIcon";
import WorkIcon from "../../../../Custom/Icon/WorkIcon";
import PriceIcon from "../../../../Custom/Icon/PriceIcon";
import UpdateWorkInfoModal from "../../Modal/UpdateWorkInfo";

const Work = ({ artist }) => {
  const [isUpdateWorkInfoModalOpen, setIsUpdateWorkInfoModalOpen] =
    useState(false);

  const closeUpdateWorkInfoModalOpen = () => {
    setIsUpdateWorkInfoModalOpen(false);
  };

  const workInfoList = [
    {
      label: "Artist Type",
      icon: <UserIcon />,
      value: artist.type.name,
    },
    {
      label: "Work Status",
      icon: <WorkIcon />,
      value: artist.workStatus,
    },
    {
      label: "Price",
      icon: <PriceIcon />,
      value: `${formatToRupee(artist.price)} / Event`,
    },
  ];

  return (
    <Fragment>
      <UpdateWorkInfoModal
        open={isUpdateWorkInfoModalOpen}
        closeModal={closeUpdateWorkInfoModalOpen}
      />
      <Divider orientation="left">
        <Typography.Title level={5}>Work</Typography.Title>
      </Divider>

      <Row gutter={8}>
        <Card
          className="w-full transition-shadow duration-300 shadow-sm"
          bordered
        >
          {workInfoList.map((info) => (
            <Card.Grid className="w-full text-left md:w-1/2" hoverable={false}>
              <AboutItem
                icon={info.icon}
                title={info.label}
                content={info.value}
              />
            </Card.Grid>
          ))}
        </Card>
      </Row>

      <div className="flex justify-center w-full pt-8">
        <Button
          onClick={() => setIsUpdateWorkInfoModalOpen(true)}
          icon={<EditOutlined />}
        >
          Update
        </Button>
      </div>
    </Fragment>
  );
};

export default Work;
