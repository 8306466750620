import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Modal, Select } from "antd";
import {
  selectEventTypes,
  selectGenres,
  selectInstruments,
  selectLanguages,
  selectLoggedInArtist,
} from "../../../../reducers/artist";
import { updateAboutInfo } from "../../../../actions/artist";

const { Option } = Select;

const UpdateAboutInfoModal = ({
  open,
  closeModal,
  artistGenres,
  artistLanguages,
  artistEventTypes,
  artistInstruments,
  artistAwards,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const artistId = useSelector(selectLoggedInArtist)?.id;
  const genres = useSelector(selectGenres);
  const instruments = useSelector(selectInstruments);
  const eventTypes = useSelector(selectEventTypes);
  const languages = useSelector(selectLanguages);

  const handleSubmit = (values) => {
    dispatch(
      updateAboutInfo({
        ...values,
        artistId,
      })
    );
    form.resetFields();
    closeModal();
  };

  const handleClose = () => {
    form.resetFields();
    closeModal();
  };

  return (
    <Modal
      open={open}
      title="About"
      okText="Update"
      cancelText="Cancel"
      okButtonProps={{
        autoFocus: true,
        htmlType: "submit",
      }}
      onCancel={handleClose}
      destroyOnClose
      modalRender={(dom) => (
        <Form
          layout="vertical"
          form={form}
          name="updateAboutInfoModal"
          initialValues={{
            updatedGenreIds: artistGenres
              ?.filter((artistGenre) => artistGenre?.genreId)
              ?.map((artistGenre) => artistGenre.genreId),
            updatedLanguageIds: artistLanguages
              ?.filter((artistLanguage) => artistLanguage?.languageId)
              ?.map((artistLanguage) => artistLanguage.languageId),
            updatedInstrumentIds: artistInstruments
              ?.filter((artistInstrument) => artistInstrument?.instrumentId)
              ?.map((artistInstrument) => artistInstrument.instrumentId),
            updatedEventTypeIds: artistEventTypes
              ?.filter((artistEventType) => artistEventType?.eventTypeId)
              ?.map((artistEventType) => artistEventType.eventTypeId),
          }}
          onFinish={handleSubmit}
        >
          {dom}
        </Form>
      )}
    >
      <Form.Item name="updatedGenreIds" label="Genre">
        <Select mode="multiple" allowClear placeholder="Rock">
          {genres.map((genre) => (
            <Option value={genre.id}>{genre.name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="updatedInstrumentIds" label="Instrument">
        <Select mode="multiple" allowClear placeholder="Guitar">
          {instruments.map((instrument) => (
            <Option value={instrument.id}>{instrument.name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="updatedEventTypeIds" label="Event Type">
        <Select mode="multiple" allowClear placeholder="Wedding">
          {eventTypes.map((eventType) => (
            <Option value={eventType.id}>{eventType.name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="updatedLanguageIds" label="Language">
        <Select mode="multiple" allowClear placeholder="English">
          {languages.map((language) => (
            <Option value={language.id}>{language.name}</Option>
          ))}
        </Select>
      </Form.Item>
    </Modal>
  );
};

export default UpdateAboutInfoModal;
