import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Image, Modal, Row, Select, Typography } from "antd";
import { fetchArtists } from "../../../../actions/artist";
import { selectCity } from "../../../../reducers/artist";
import commonConstants from "../../../../constants/common";
import CityButton from "./CityButton";
import bangalore from "../../../../images/cities/bangalore.png";
import delhi from "../../../../images/cities/delhi.png";
import hyderabad from "../../../../images/cities/hyderabad.png";
import india from "../../../../images/cities/india.png";
import mumbai from "../../../../images/cities/mumbai.png";
import pune from "../../../../images/cities/pune.png";
import { selectCities } from "../../../../reducers/address";

const { Option } = Select;

const SelectCity = () => {
  const dispatch = useDispatch();
  const city = useSelector(selectCity);
  const cities = useSelector(selectCities);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCityChange = (updatedCityId) => {
    if (updatedCityId !== city?.id) {
      dispatch(
        fetchArtists({
          cityId: updatedCityId,
        })
      );
    }
    setIsModalOpen(false);
  };

  const citiesForModal = [
    {
      data: commonConstants.CITIES.BANGALORE,
      src: bangalore,
    },
    {
      data: commonConstants.CITIES.HYDERABAD,
      src: hyderabad,
    },
    {
      data: commonConstants.CITIES.MUMBAI,
      src: mumbai,
    },
    {
      data: commonConstants.CITIES.PUNE,
      src: pune,
    },
    {
      data: commonConstants.CITIES.DELHI,
      src: delhi,
    },
  ];

  const citiesForSelect = cities.filter((city) => {
    const cityIds = Object.values(commonConstants.CITIES).map(
      (cityObject) => cityObject.id
    );
    return !cityIds.includes(city.id);
  });

  return (
    <Fragment>
      <Modal
        title="Select City"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Row justify="center">
          <Col className="flex flex-col items-center" sm={5} md={6} lg={6}>
            <Image
              className="hover:cursor-pointer"
              onClick={() => handleCityChange(null)}
              src={india}
              width={80}
              preview={false}
            />
            <Typography.Paragraph
              className="hover:cursor-pointer"
              onClick={() => handleCityChange(null)}
            >
              All Cities
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row className="flex justify-center w-full" gutter={16} wrap>
          {citiesForModal.map((city, index) => (
            <Col
              key={index}
              className="flex flex-col items-center"
              sm={5}
              md={6}
              lg={6}
            >
              <Image
                className="hover:cursor-pointer"
                onClick={() => handleCityChange(city.data?.id)}
                src={city.src}
                width={100}
                preview={false}
              />
              <Typography.Paragraph
                className="hover:cursor-pointer"
                onClick={() => handleCityChange(city.data?.id)}
              >
                {city.data?.name}
              </Typography.Paragraph>
            </Col>
          ))}
        </Row>
        <div className="w-full">
          <Select
            style={{ width: "100%" }}
            value={
              citiesForSelect.find(
                (cityForSelect) => cityForSelect.id === city?.id
              )?.id
            }
            placeholder="Nagpur, Maharashtra"
            onChange={(value) => handleCityChange(value)}
          >
            {citiesForSelect.map((city) => (
              <Option
                value={city.id}
              >{`${city.name}, ${city.state.name}`}</Option>
            ))}
          </Select>
        </div>
        <Typography.Paragraph
          className="mt-4 mb-0 text-xs text-center"
          type="secondary"
        >
          *Artists will be fetched based on the city selected.
        </Typography.Paragraph>
      </Modal>
      <CityButton city={city?.name} handleClick={() => setIsModalOpen(true)} />
    </Fragment>
  );
};

export default SelectCity;
