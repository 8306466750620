import Icon from "@ant-design/icons";
import customIcon from "../../../images/icons/work.png";

const CustomIcon = () => (
  <img
    src={customIcon}
    alt="Work Icon"
    style={{ width: "35px" }} // You can adjust the size here
  />
);

const WorkIcon = () => <Icon component={CustomIcon} />;

export default WorkIcon;
