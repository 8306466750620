import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Select, Typography } from "antd";
import { createRestaurant } from "../../../../actions/client";
import { ROUTES_MAP } from "../../../../constants/common";
import {
  confirmPasswordValidator,
  isStringNumeric,
  isStrongPassword,
} from "../../../../utils/formValidator";

const { Option } = Select;

const SignUpForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleSubmitHandler = (values) => {
    dispatch(createRestaurant(values));
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full pt-10 md:w-2/3">
        <Form
          name="restaurantSignUp"
          onFinish={handleSubmitHandler}
          form={form}
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input your Restaurant Name!",
              },
              {
                max: 50,
                message: "Name cannot be more than 50 characters!",
              },
            ]}
          >
            <Input placeholder="XYZ Restaurant" />
          </Form.Item>

          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input placeholder="name@example.com" />
          </Form.Item>

          <Form.Item
            name="contact"
            label="Phone Number"
            rules={[
              { required: true, message: "Please input your phone number!" },
              {
                len: 10,
                message: "Phone Number should be of length 10!",
              },
              () => ({
                validator: isStringNumeric("Phone Number"),
              }),
            ]}
          >
            <Input
              addonBefore={
                <Form.Item name="prefix" noStyle>
                  <Select defaultValue="91" style={{ width: 70 }}>
                    <Option value="91">+91</Option>
                  </Select>
                </Form.Item>
              }
              placeholder="90324XXXXX"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              () => ({
                validator: isStrongPassword(),
              }),
            ]}
            hasFeedback
          >
            <Input.Password placeholder="********" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator: confirmPasswordValidator(getFieldValue),
              }),
            ]}
          >
            <Input.Password placeholder="********" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Signup
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div>
        <Typography.Paragraph>
          Already have an account?{" "}
          <Typography.Link
            onClick={() => navigate(ROUTES_MAP.RESTAURANT_LOGIN.path)}
          >
            Login!
          </Typography.Link>
        </Typography.Paragraph>
      </div>
    </div>
  );
};

export default SignUpForm;
