import Icon from "@ant-design/icons";
import customIcon from "../../../images/icons/trophy.png";

const CustomIcon = () => (
  <img
    src={customIcon}
    alt="Award Icon"
    style={{ width: "35px" }} // You can adjust the size here
  />
);

const AwardIcon = () => <Icon component={CustomIcon} />;

export default AwardIcon;
