import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Modal, Space, Typography } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CompassOutlined,
  ExclamationCircleFilled,
  UserOutlined,
} from "@ant-design/icons";
import {
  constructAddress,
  createFullName,
  formatToRupee,
  formatHumanReadableDate,
} from "../../../utils/common";
import { BOOKING_STATUS } from "../../../constants/common";
import { cancelBookingById } from "../../../actions/client";

const Booking = ({ booking }) => {
  const dispatch = useDispatch();
  const address = booking?.additionalInfo?.address;
  const artist = booking?.additionalInfo?.artist;
  const client = booking?.additionalInfo?.client;
  const bookingStatus = booking?.status;
  const status = BOOKING_STATUS[bookingStatus];
  const eventType = booking?.additionalInfo?.eventType;
  const startTime = booking?.startTime;
  const endTime = booking?.endTime;
  const createdAt = booking?.createdAt;
  const updatedAt = booking?.updatedAt;
  const price = booking?.additionalInfo?.price;

  const handleCancelBooking = () => {
    Modal.confirm({
      title: "Are you sure cancel this booking?",
      icon: <ExclamationCircleFilled />,
      content:
        "This will cancel the booking. You advance payment may not be refunded back entirely!",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        dispatch(
          cancelBookingById({
            clientId: client?.id,
            bookingId: booking?.id,
          })
        );
      },
      onCancel() {},
    });
  };

  const isCancelBookingDisabled = () => {
    return (
      bookingStatus === BOOKING_STATUS.CANCELLED_BY_ARTIST.id ||
      bookingStatus === BOOKING_STATUS.CANCELLED_BY_CLIENT.id ||
      bookingStatus === BOOKING_STATUS.COMPLETED.id
    );
  };

  return (
    <div className="w-full p-6 rounded-md shadow">
      <div className="flex justify-between">
        <Typography.Title level={5} className="mb-0">
          {eventType?.name} Booking
        </Typography.Title>
        <Typography.Paragraph className="mb-0 font-extrabold">
          {price ? formatToRupee(price) : "Price not confirmed!"}
        </Typography.Paragraph>
      </div>
      <Divider />
      <Typography.Paragraph className="mb-0" type="secondary">
        <CompassOutlined /> {constructAddress(address)}
      </Typography.Paragraph>
      <Typography.Paragraph className="mb-0" type="secondary">
        <UserOutlined /> {createFullName(artist)}
      </Typography.Paragraph>
      <Divider />
      <Typography.Paragraph className="mb-0" type="secondary">
        <ClockCircleOutlined /> Event Time -
      </Typography.Paragraph>
      <Typography.Paragraph className="mb-0" type="secondary">
        {formatHumanReadableDate(startTime)} to{" "}
        {formatHumanReadableDate(endTime)}
      </Typography.Paragraph>
      <Divider />
      <Typography.Paragraph className="mb-0" type="secondary">
        Booked on {formatHumanReadableDate(createdAt)}
      </Typography.Paragraph>
      <Typography.Paragraph className="mb-0" type="secondary">
        Last updated on {formatHumanReadableDate(updatedAt)}
      </Typography.Paragraph>
      <Divider />
      <div className="flex items-center justify-between h-fit">
        <Typography.Paragraph
          className="px-3 py-2 mb-0 rounded"
          style={{
            backgroundColor: status?.color?.background || "#ffffff", // Set background color
            color: status?.color?.text || "#000000", // Set text color
          }}
        >
          {status?.name}
        </Typography.Paragraph>
        <Button
          disabled={isCancelBookingDisabled()}
          danger
          onClick={handleCancelBooking}
        >
          Cancel Booking
        </Button>
      </div>
    </div>
  );
};

export default Booking;
